import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';

@Component({
    selector: 'salaso-date-picker-updated',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerUpdatedComponent implements OnInit {
    date = moment();

    @Input() defaultDate;
    @Input() maxDate;
    @Input() minDate;
    @Input() openOnLaunch: boolean;
    @Input() placeholder: string;
    @Input() required: boolean;
    @Input() skipInitEmit = false;

    @Output() dateChanged: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('picker') picker;

    selectedDate;

    constructor(private adapter: DateAdapter<any>) {
        this.adapter.setLocale('en-ie');
    }

    ngOnInit() {
        if (this.openOnLaunch) {
            setTimeout(() => {
                this.picker.open();
            });
        }

        if (this.selectedDate === 'empty') {
            this.selectedDate = null;
        } else {
            this.selectedDate = this.defaultDate ? new Date(this.defaultDate) : new Date();
        }

        // propagate default value
        if (!this.skipInitEmit) {
            this.dateChanged.emit(moment(this.selectedDate));
        }
    }

    handleDateSelected($event: Date) {
        this.selectedDate = $event;
        this.dateChanged.emit($event);
    }
}
