import {
  Injectable
} from '@angular/core';
import {
  environment
} from '@env/environment';
import {
  ApiService
} from '@app/shared/services/api.service';
import {
  BehaviorSubject
} from 'rxjs';
import {
  TemplateCreate,
  TemplateExercise,
  Exercise
} from '@app/shared/models';
import { TemplatesComponent } from '@app/modules/prescription/pages/templates/templates.component';
import { AssignLeaflet } from '@app/shared/models/assign_program.model';
import { UserLeaflet } from '@app/shared/models/leaflet.model';

// Comment

@Injectable()
export class TemplateService {

  constructor(private api_service: ApiService) {
    this.current_template.subscribe(res => {
      console.log(`Template Service Updated`, res);
    });

    this.current_exercises.subscribe(res => {
      console.log(`Exercises in template updated`);
    });

    this.current_leaflets.subscribe(res => {
      console.log(`Leaflets in template updated`);
    });
  }

  private template_to_create = new BehaviorSubject({ name: '', Template_Exercises: [], Template_Leaflets: [] } as TemplateCreate);
  current_template = this.template_to_create.asObservable();

  private exercises_in_template = new BehaviorSubject([] as Exercise[]);
  current_exercises = this.exercises_in_template.asObservable();

  private leaflets_in_template = new BehaviorSubject([] as UserLeaflet[]);
  current_leaflets = this.leaflets_in_template.asObservable();

  private assigned_template_rename = new BehaviorSubject('' as String);
  current_rename = this.assigned_template_rename.asObservable();

  private was_checked = new BehaviorSubject(false);
  update_now = this.was_checked.asObservable();

  update_sidebar(boolean) {
    this.was_checked.next(boolean);
  }

  set_reassigned_name(string_to_reassign: string) {
    this.assigned_template_rename.next(string_to_reassign);
  }

  set_template_subject(template: TemplateCreate) {
    this.template_to_create.next(template);
  }

  set_template_name(name: string) {
    let template;
    this.current_template.subscribe((res: TemplateCreate) => {
      template = res;
      template.name = name;
    });
    this.set_template_subject(template);
  }

  add_template_exercise(template_exercise: Exercise) {

    const exercise_to_add: TemplateExercise = {
      'exercise_id': template_exercise.id
    };

    let template;
    let exercises: Exercise[];
    this.template_to_create.subscribe((res: TemplateCreate) => {
      template = res;
    });

    if (template) {
      template.Template_Exercises.push(exercise_to_add);
      this.set_template_subject(template);

      this.exercises_in_template.subscribe(current_exercises => {
        exercises = current_exercises;
      });

      exercises.push(template_exercise);

      this.exercises_in_template.next(exercises);

    }

  }

  remove_template_exercise(template_exercise: Exercise) {

    let template: TemplateCreate;
    let exercises: Exercise[];

    this.template_to_create.subscribe((current_res: TemplateCreate) => {
      template = current_res;
    });

    this.exercises_in_template.subscribe((current_exercises: Exercise[]) => {
      exercises = current_exercises;
    });

    let i = 0;
    template.Template_Exercises.forEach(template_exercises => {
      if (template_exercises.exercise_id === template_exercise.id) {
        template.Template_Exercises.splice(i, 1);
      }
      i++;
    });

    let j = 0;
    exercises.forEach(ex => {
      if (ex.id === template_exercise.id) {
        exercises.splice(j, 1);
      }
      j++;
    });

    this.set_template_subject(template);
    this.exercises_in_template.next(exercises);

  }

  add_template_leaflet(template_leaflet: UserLeaflet) {

    const leaflet_to_add: AssignLeaflet = {
      'leaflet_id': template_leaflet.id
    };

    let template;
    let leaflets: UserLeaflet[];
    this.template_to_create.subscribe((res: TemplateCreate) => {
      template = res;
    });

    if (template) {
      template.Template_Leaflets.push(leaflet_to_add);
      this.set_template_subject(template);

      this.leaflets_in_template.subscribe(current_leaflets => {
        leaflets = current_leaflets;
      });

      leaflets.push(template_leaflet);

      this.leaflets_in_template.next(leaflets);

    }

  }

  remove_template_leaflet(template_leaflet: UserLeaflet) {

    let template: TemplateCreate;
    let leaflets: UserLeaflet[];

    this.template_to_create.subscribe((current_res: TemplateCreate) => {
      template = current_res;
    });

    this.leaflets_in_template.subscribe((current_leaflets: UserLeaflet[]) => {
      leaflets = current_leaflets;
    });

    let i = 0;
    template.Template_Leaflets.forEach(template_leaflets => {
      if (template_leaflet.id === template_leaflet.id) {
        template.Template_Leaflets.splice(i, 1);
      }
      i++;
    });

    let j = 0;
    leaflets.forEach(leaflet => {
      if (leaflet.id === template_leaflet.id) {
        leaflets.splice(j, 1);
      }
      j++;
    });

    this.set_template_subject(template);
    this.leaflets_in_template.next(leaflets);

  }

  clear_all() {
    let template: TemplateCreate;
    this.template_to_create.subscribe((res: TemplateCreate) => {
      template = res;
    });
    this.template_to_create.next({ name: '', Template_Exercises: [], Template_Leaflets: [] } as TemplateCreate);
    this.exercises_in_template.next([]);
    this.leaflets_in_template.next([]);
  }


  // Crud
  public create_template(template: TemplateCreate) {

    return this.api_service.post(`${environment.endpoints.exercise.templates}`, template);
  }
  public create_draft_template() {

    return this.api_service.post(`${environment.endpoints.exercise.drafttemplate}`, {});
  }
  public update_draft_template(draft_id, draft_template) {
    return this.api_service.put(`${environment.endpoints.exercise.drafttemplate}${draft_id}`, draft_template);
  }

  public copy_template_to_draft (template_id) {
    return this.api_service.post(`${environment.endpoints.exercise.drafttemplate}${template_id}`, {});
  }
  public save_as_new_template(template_id: string) {
    return this.api_service.post(`${environment.endpoints.exercise.drafttemplate}save/${template_id}`, {});
  }
  public update_old_template(template_id: string) {
    return this.api_service.post(`${environment.endpoints.exercise.drafttemplate}update_old/${template_id}`, {});
  }



  // tslint:disable-next-line:max-line-length
  public get_many_templates(limit?: number, offset?: number, template_category_id?: string, template_sub_category_id?: string, search?: string) {
    if (search) {
      return this.api_service.get(`${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}&search=${search}`);
    }

    if (template_category_id && template_sub_category_id) {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}&template_category=${template_category_id}&template_sub_category=${template_sub_category_id}`);
    } else if (template_category_id) {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}&template_category=${template_category_id}`);
    } else if (limit || offset) {
      return this.api_service.get(`${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}`);
    } else {
      return this.api_service.get(`${environment.endpoints.exercise.templates}`);
    }
  }


  public get_template(template_id: string) {

    return this.api_service.get(`${environment.endpoints.exercise.templates}${template_id}`);
  }

  public update_template (updated_template, current_template_id) {
    return this.api_service.put(`${environment.endpoints.exercise.templates}${current_template_id}`, updated_template);
  }


  // tslint:disable-next-line:max-line-length
  public get_touchworks_templates(org_id: Number, limit?: number, offset?: number, template_category_id?: string, template_sub_category_id?: string, search?: string, special?: string) {
    if (search) {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}&search=${search}&specialty=${special}`);
    }

    if (template_category_id && template_sub_category_id) {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}&template_category=${template_category_id}&template_sub_category=${template_sub_category_id}&specialty=${special}`);
    } else if (template_category_id) {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}&template_category=${template_category_id}&specialty=${special}`);
    } else if (limit || offset) {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}&specialty=${special}`);
    } else {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.exercise.templates}?specialty=${special}`);
    }
  }

  // tslint:disable-next-line:max-line-length
  public get_org_templates(org_id: Number, limit?: number, offset?: number, template_category_id?: string, template_sub_category_id?: string, search?: string) {
    if (search) {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}&search=${search}`);
    }

    if (template_category_id && template_sub_category_id) {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}&template_category=${template_category_id}&template_sub_category=${template_sub_category_id}`);
    } else if (template_category_id) {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}&template_category=${template_category_id}`);
    } else if (limit || offset) {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}`);
    } else {
      return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.exercise.templates}`);
    }
  }


  // tslint:disable-next-line:max-line-length
  public get_physio_templates(physio_id: Number, limit?: number, offset?: number, template_category_id?: string, template_sub_category_id?: string, search?: string) {
    if (search) {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.physio.physios}${physio_id}${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}&search=${search}`);
    }

    if (template_category_id && template_sub_category_id) {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.physio.physios}${physio_id}${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}&template_category=${template_category_id}&template_sub_category=${template_sub_category_id}`);
    } else if (template_category_id) {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.physio.physios}${physio_id}${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}&template_category=${template_category_id}`);
    } else if (limit || offset) {
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.physio.physios}${physio_id}${environment.endpoints.exercise.templates}?limit=${limit}&offset=${offset}`);
    } else {
      return this.api_service.get(`${environment.endpoints.physio.physios}${physio_id}${environment.endpoints.exercise.templates}`);
    }
  }

  public get_categories() {
    return this.api_service.get(`${environment.endpoints.filters.template.categories}`);
  }

  public get_sub_categories(category_id?: string) {
    if (category_id) {
      return this.api_service.get(`${environment.endpoints.filters.template.sub_categories}?template_category=${category_id}`);
    } else {
      return this.api_service.get(`${environment.endpoints.filters.template.sub_categories}`);
    }
  }

  public add_template_to_favourite(template_id: string) {
    return this.api_service.post(`${environment.endpoints.exercise.templates}${template_id}/favourites`, '');
  }

  public delete_favourite_template(template_id: string) {
    return this.api_service.delete(`${environment.endpoints.exercise.exercises}${template_id}/favourites`, {});
  }

  public get_favourite_templates(template_id: string) {
    return this.api_service.get(`${environment.endpoints.user.users}${template_id}/favourites/exercises`);
  }

  public delete_physio_template(physio_id: Number, template_id: string) {
    // tslint:disable-next-line:max-line-length
    return this.api_service.delete(`${environment.endpoints.physio.physios}${physio_id}${environment.endpoints.exercise.templates}${template_id}`, {});
  }

  public delete_org_template(org_id: Number, template_id: string) {
    // tslint:disable-next-line:max-line-length
    return this.api_service.delete(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.exercise.templates}${template_id}`, {});
  }

}
