import { Pipe, PipeTransform } from '@angular/core';

// Usage {{ string | truncate:[20]}}
// Or {{ string | truncate:[20, '...']}}
@Pipe({ name: 'truncate' })
export class TruncateTextPipe implements PipeTransform {
    transform(value: string, args: string[]): string {
      // const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
      // const trail = args.length > 1 ? args [1] : '...';
      // return value.length ? value.substring(0, limit) + trail : value;

      const limit = parseInt(args[0], 10);
      if (value.length > limit) {
        return value.substring(0, limit) + '...';
      } else {
        return value;
      }
    }
}
