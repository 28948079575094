import { Component, OnInit, Renderer2 } from '@angular/core';
import { UserService, ProgramService } from '@app/shared/services';
import { UserProgram, PaginationData } from '@app/shared/models';
import { QuestionnaireService } from '@app/shared/services';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'salaso-program-list',
  templateUrl: './program-list.component.html',
  styleUrls: ['./program-list.component.scss']
})

export class ProgramListComponent implements OnInit {

  user_programs: UserProgram[];
  user_id: Number;
  pagination: PaginationData;
  result: any;
  invites: Boolean;
  // tslint:disable-next-line:max-line-length
  constructor(private program_service: ProgramService, private user_service: UserService, private questionnaire_service: QuestionnaireService, private renderer: Renderer2) {
    this.user_id = this.user_service.get_user_id();
    this.program_service.get_users_programs(this.user_id)
      .subscribe((res: any) => {
        this.user_programs = res.programs;
        console.log(res);

        this.pagination = res.paginationData;
      });
      console.log(this.user_id);
    this.questionnaire_service.get_questionnaire_invitations(this.user_id).subscribe((res: any) => {
      console.log('RES ', res.questionnaire_invitations);
      if (res.questionnaire_invitations.length > 0) {
        this.result = res.questionnaire_invitations;
        this.invites = true;
      } else {
        this.invites = false;
      }
    });

    // Patient View Styles Preserved
    if (this.user_service.get_user_role() === 5) {
      this.renderer.addClass(document.body, "patient-view");
    }
  }

  ngOnInit(): void {

  }

}
