import { Injectable } from '@angular/core';
import { UserService } from '@app/shared/services/user.service';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterGaurdService implements CanActivate {

  role;
  isAuthenticated;

  // tslint:disable-next-line:max-line-length
  constructor(private user_service: UserService, private router: Router, private route: ActivatedRoute) {
   }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    this.user_service.current_user_role.subscribe(res => { this.role = res; });

    this.user_service.is_authenticated.subscribe(res => { this.isAuthenticated = res; });

    const permission = route.data['permission'];

    let canActivate: boolean;

    if (!permission) { throw new Error('Permissions aren\'t set up correctly'); }

    //  (this.isAuthenticated);
    canActivate = permission.only.includes(this.role);

    if (!canActivate) {

      switch (this.role) {
        case 1:
          this.router.navigate(['exercises']);
          break;
        case 2:
          this.router.navigate(['exercises']);
          break;
        case 4:
          this.router.navigate(['exercises']);
          break;
        case 5:
          this.router.navigate(['programs']);
          break;
        default:
          this.router.navigate(['auth']);
          break;
      }
      // this.router.navigate([permission.redirectTo]);
      //  (this.isAuthenticated);
     }

    return canActivate;


  }
}
