import { Component, Input, Output, EventEmitter, OnInit, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { SurveyInvite, PatientOrgSurvey, SurveyAssigned } from '@app/shared/models/survey.model';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { UserService } from '@app/shared/services';
import * as moment from 'moment';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SurveyAnswersDialogComponent } from '../survey-answers-dialog/survey-answers-dialog.component';

@Component({
    selector: 'salaso-patient-survey-list',
    templateUrl: './patient-survey-list.component.html',
    styleUrls: ['./patient-survey-list.component.scss']
})
export class PatientSurveyListComponent implements OnInit, OnChanges {
    @ViewChild('surveyChart') surveyChart;

    @Input() surveyInvites: SurveyInvite[];
    @Input() patientOrgSurveys: PatientOrgSurvey[];
    @Input() surveysAssignedList:  SurveyAssigned;
    @Input() isPatientView = false;
    @Input() isComplete: 0 | 1 = 0;
    @Input() isPhysioSurvey: 0 | 1 = 0;
    @Input() isStatus: 0 | 1 = 1;
    @Input() userGroupId: string;

    @Output() surveyChange = new EventEmitter();
    @Output() revokeInvite = new EventEmitter<string>();
    @Output() startSurvey = new EventEmitter<PatientOrgSurvey>();
    @Output() completeFilterChange = new EventEmitter<{ completeValue: number, statusValue: number }>();
    @Output() surveyNameFilterChange = new EventEmitter<string>();

    surveyId: string;
    userToggles;
    isUserGroupView = false;
    surveyHeader = '';

    constructor(private dialog: MatDialog, private userService: UserService) { }

    ngOnInit() {
        this.isUserGroupView = this.userGroupId ? true : false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['surveyInvites'] && changes['surveyInvites'].currentValue) {
            if (this.isComplete === 1) {
                this.surveyHeader = 'Completed Survey';
            } else if (this.isComplete === 0 && this.isStatus === 1) {
                this.surveyHeader = 'Not completed Survey';
            } else {
                this.surveyHeader = this.isPhysioSurvey ? 'Cancelled Surveys' : 'Revoked Survey';
            }
        }
    }



    handleOpenSurveyChartDialog(surveyId: string) {
        this.surveyId = surveyId;

        this.dialog.open(this.surveyChart, {
            width: '70rem',
        });
    }

    handleOpenConfirmDialog(surveyId: string) {
        const headerMsg = this.isPatientView || this.isPhysioSurvey ? 'Cancel survey invite' : 'Revoke survey invite';
        const message = this.isPatientView || this.isPhysioSurvey ? 'Are you sure you wish to cancel this survey invite?' : 'Are you sure you wish to revoke this survey invite?';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '35rem',
            data: {
                header: headerMsg,
                message: message,
                button1: 'No',
                button2: 'Yes',
            }
        });

        this.onDialogAfterClose(dialogRef, surveyId);
    }

    onDialogAfterClose(dialogRef, surveyId: string) {
        dialogRef.afterClosed().subscribe(
            (response) => this.onDialogAfterCloseSuccess(response, surveyId),
            (error) => this.onError(error)
        );
    }

    onDialogAfterCloseSuccess(response, surveyId: string) {
        if (response === 'yes') {
            this.emitRevokeInvite(surveyId);
        }
    }

    handleOpenSurveyAnswersDialog(survey: SurveyInvite) {
        this.dialog.open(SurveyAnswersDialogComponent, {
            width: '65rem',
            data: survey.patientSurveyInvites ? survey.patientSurveyInvites : survey,
        });
    }

    emitSurveyTypeChange(event: MatRadioChange) {
        this.surveyChange.emit(event.value);
    }

    emitCompleteFilterChange(event: MatRadioChange) {
        let completeValue = event.value;
        let statusValue = 1;

        if (completeValue === 2) {
            statusValue = 0;
            completeValue = 0;
        }

        this.completeFilterChange.emit({completeValue, statusValue});
    }

    emitRevokeInvite(surveyId: string) {
        this.revokeInvite.emit(surveyId);
    }

    emitStartSurvey(survey: PatientOrgSurvey) {
        this.startSurvey.emit(survey);
    }

    emitSurveyNameFilter(event: MatRadioChange) {
        this.surveyNameFilterChange.emit(event.value);
    }

    isAvailableAfter(availableAfterDate) {
        return moment(availableAfterDate).startOf('day').isAfter();
    }

    onError(error) {
    }
}
