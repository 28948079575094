import { Component, OnInit } from '@angular/core';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'salaso-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss']
})
export class BrandingComponent implements OnInit {

  is_authenticated: Boolean;

  constructor(private user_service: UserService) { }

  ngOnInit() {

    this.user_service.is_authenticated.subscribe((res: Boolean) => {
      this.is_authenticated = res;
    });

  }

}
