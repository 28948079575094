import {
  Injectable
} from '@angular/core';
import {
  environment
} from '@env/environment';
import {
  ApiService
} from '@app/shared/services/api.service';


@Injectable()
export class ContentService {


  constructor(private api_service: ApiService) {}



}
