/*
 *
 *  Intercept all request to handle errors and auto refresh token if needed
 *
 */

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import {
  Injectable,
  Injector
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Observable
} from 'rxjs';
import {
  catchError,
  switchMap
} from 'rxjs/operators';
import {
  UserService
} from '../services';
import { throwError } from 'rxjs';
/**
 *
 *
 * @export
 * @class RequestInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  /**
   *Creates an instance of RequestInterceptor.
   * @param {UserService} user_service
   * @param {Injector} injector
   * @param {Router} router
   * @memberof RequestInterceptor
   */

  constructor(private user_service: UserService, private injector: Injector, private router: Router) {}

  /**
   *
   *
   *
   * @param {HttpRequest <any>} req
   * @param {HttpHandler} next
   * @returns {Observable <HttpEvent <any>>}
   * @memberof RequestInterceptor
   */

  intercept(req: HttpRequest <any> , next: HttpHandler): Observable <HttpEvent<any>> {

    if (this.user_service.get_token('access_token')) {
      const authReq = req.clone({
        headers: req.headers.set('x-access-token', this.user_service.get_token('access_token'))
                .set('Cache-Control', 'no-cache')
                .set('Pragma', 'no-cache')
                .set('Expires', 'Sat, 02 Jan 2000 00:00:00 GMT')
                .set('If-Modified-Since', '0')
      });
      return next.handle(authReq).pipe(catchError((error) => {
        // tslint:disable-next-line:no-console
        console.debug(error);
        if (error.error.error === 'jwt expired') {
          return this.user_service.refresh_token().pipe(switchMap(t => {
            this.user_service.save_token('access_token', t.access_token);
            this.user_service.save_token('refresh_token', t.refresh_token);
            const authRequest = req.clone({
              headers: req.headers.set('x-access-token', t.access_token)
                .set('Cache-Control', 'no-cache')
                .set('Pragma', 'no-cache')
                .set('Expires', 'Sat, 02 Jan 2000 00:00:00 GMT')
                .set('If-Modified-Since', '0')
            });
            return next.handle(authRequest);
          }), catchError((err: HttpErrorResponse) => {

            this.router.navigate(['auth']);
            return Observable.throw(err);
          }));
        }

        return throwError(error);
      }) );

    } else {
      return next.handle(req).pipe(catchError((error) => {
        
        return throwError(error);
        // return Observable.throw(error);
      }));
    }
  }
}
