import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoggingService, UserService } from '@app/shared/services';
import { Router } from '@angular/router';
import { UserActivityGoal, UserActivityScore } from '@app/shared/models';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'salaso-activity-container',
  templateUrl: './activity-container.component.html',
  styleUrls: ['./activity-container.component.scss']
})
export class ActivityContainerComponent implements OnInit {
  @ViewChild('successDialog') success_dialog: TemplateRef<any>;
  @ViewChild('failDialog') error_dialog: TemplateRef<any>;

  // Data
  user_id: Number;
  activity_id: Number;
  title: String;
  score: Number;

  // Form Groups
  activity_form: FormGroup;
  score_form: FormGroup;

  constructor(private form_builder: FormBuilder, private logging_service: LoggingService, private user_service: UserService,
    private router: Router, private dialog: MatDialog) {

    // Getting user id
    this.user_id = this.user_service.get_user_id();

    // Gets activity title and score, and populate form with existing data
    this.logging_service.get_user_activity_goal(this.user_id)
      .subscribe((res: UserActivityGoal) => {
        if (res.activity.Activity_Scores[0]) {
          this.title = res.activity.title;
          this.score = res.activity.Activity_Scores[0].score;
        } else {
          this.title = '';
          this.score = 0;
        }

        this.activity_id = res.activity.id;

        this.activity_form = this.form_builder.group({
          'title': [this.title, Validators.required]
        });

        this.score_form = this.form_builder.group({
          'score': [this.score, Validators.required]
        });
      });
  }

  ngOnInit() {

  }

  // Formats label
  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }
    if (value >= 1) {
      return Math.round(value) + '/10';
    }

    return value;
  }


  // updates activity and score
  updateActivity() {
    /**
     * @param {Number} user_id
     * @param {Number} activity_id
     * @param {String} activity_form.value.title
     * @memberof ActivityContainerComponent
     */
    this.logging_service.update_user_activity_goal(this.user_id, this.activity_id, this.activity_form.value.title)
      .subscribe((res: UserActivityGoal) => {
        this.open_success_dialog();
      }, err => {
        this.open_error_dialog();
      });

    /**
     * @param {Number} activity_id
     * @param {Number} score_form.value.title
     * @memberof ActivityContainerComponent
     */
    this.logging_service.create_activity_score(this.activity_id, this.score_form.value.score)
      .subscribe((res: UserActivityScore) => {

      }, err => {
        this.open_error_dialog();
      });
  }

  // Navigates to stats page
  returnToStatsPage() {
    this.router.navigate(['/programs/stats']);
  }

  // Open success dialog
  open_success_dialog() {
    this.dialog.open(this.success_dialog);
    this.returnToStatsPage();
  }

  // Open error dialog
  open_error_dialog() {
    this.dialog.open(this.error_dialog);
  }

}
