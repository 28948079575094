import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'salaso-user-tabs',
  templateUrl: './user-tabs.component.html',
  styleUrls: ['./user-tabs.component.scss']
})


export class UserTabsComponent implements OnInit {
  tabView: any;
  navLinks: any;

  constructor(public router: Router) {
    this.navLinks = [
      { path: '/programs', label: 'Programs'},
      { path: '/programs/stats', label: 'Stats' },
      { path: '/programs/activity', label: 'Activity' },
      // { path: '/programs/surveys', label: 'Surveys' }
    ];
  }

  ngOnInit() {}

  isActive() {

  }
}
