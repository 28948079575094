import {
  Component,
  OnInit,
  AfterViewInit
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  ProgramService,
  LoggingService,
  ApiService
} from '@app/shared/services';
import {
  Http
} from '@angular/http';
import {
  environment
} from '@env/environment';
import {
  Headers
} from '@angular/http';
import {
  MatDialog, MatSnackBar
} from '@angular/material';
import {
  LoggingDialogComponent
} from '../../components/logging-dialog/logging-dialog.component';
import {
  ComplianceLoggerComponent
} from '@app/modules/exercises/components/compliance-logger/compliance-logger.component';

declare var myFunction: any;
@Component({
  selector: 'salaso-exercise-details',
  templateUrl: './exercise-details.component.html',
  styleUrls: ['./exercise-details.component.scss']
})
export class ExerciseDetailsComponent implements OnInit, AfterViewInit {
  program: any;
  program_id: number;
  current_exercise_id: any;
  console = console;
  current_exercise_details: any;
  exercises: any;
  poster: any;
  video_link: any;
  image_endpoint = environment.image_url;
  completed: boolean;
  is_reps_sets: boolean;
  is_heart_rate: boolean;

  button_timer = 0;
  // tslint:disable-next-line:max-line-length
  constructor(private api_service: ApiService, public snackBar: MatSnackBar, public dialog: MatDialog, private http: Http, private router: Router, private activated_route: ActivatedRoute, private program_service: ProgramService, private logging_service: LoggingService) {
    this.exercises = '';
    this.completed = false;
  }


  ngOnInit() {
    this.activated_route.params.subscribe(res => {
      this.program_id = res.program_id;
      this.current_exercise_id = res.exercise_id - 1;

      this.program_service.get_program(this.activated_route.snapshot.params.program_id).subscribe((user_program: any) => {
        this.program = user_program.program;
        this.check_compliance();
        this.get_video();
        this.check_type();
      }, () => {
        this.router.navigate(['not_found']);
      });
    });

  }

  // Checks if exercise is sets & reps or HR
  check_type() {
    if (this.program.Program_Exercises[this.current_exercise_id].hr_min &&
      this.program.Program_Exercises[this.current_exercise_id].hr_max &&
      this.program.Program_Exercises[this.current_exercise_id].reps &&
      this.program.Program_Exercises[this.current_exercise_id].sets) {
      this.is_reps_sets = true;
      this.is_heart_rate = true;
    } else if (this.program.Program_Exercises[this.current_exercise_id].reps &&
      this.program.Program_Exercises[this.current_exercise_id].sets) {
      this.is_reps_sets = true;
      this.is_heart_rate = false;
    } else if (this.program.Program_Exercises[this.current_exercise_id].hr_min &&
      this.program.Program_Exercises[this.current_exercise_id].hr_max) {
      this.is_heart_rate = true;
      this.is_reps_sets = false;
    } else {
      this.is_reps_sets = false;
      this.is_heart_rate = false;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => myFunction(), 300);
  }

  // Checks if compliance for exercise is complete
  check_compliance() {
    const compliance = [];
    this.program.Program_Exercises.forEach(program_exercise => {
      compliance.push(program_exercise.daily - program_exercise.Activity_Records.length);
    });

    let pain = [];
    let effort = [];
    let activity_score = [];
    const date = new Date().toISOString();

    this.logging_service.get_user_effort(this.program.user_login_id, date, date)
      .toPromise().then(res => {
        // tslint:disable-next-line:triple-equals
        const program = res.programs.find(x => x.id == this.program_id);
        effort = program.User_Efforts;
      });

    this.logging_service.get_user_activity_scores(this.program.user_login_id, date, date)
      .toPromise().then(res => {
        activity_score = res.activities[0].Activity_Scores;
      });

    this.logging_service.get_user_pains(this.program.user_login_id, date, date)
      .toPromise().then(res => {
        pain = res.pains;
      }).then(res2 => {
        if (pain.length <= 0 || effort.length <= 0) {
          if (compliance.every(thing => thing === 0)) {
            const dialog = this.dialog.open(ComplianceLoggerComponent, {
              data: {
                program_id: this.program_id,
                program: this.program
              },
              disableClose: false,
              width: '90%',
              height: '90%'
            });

            dialog.afterClosed()
              .subscribe(data => {
                if (data) {
                } else {

                }
              });
            this.completed = true;
          }
        } else {
          this.completed = true;
        }
      });

  }

  // Updates id
  update_id(new_id) {
    this.current_exercise_id = new_id;
    setTimeout(() => myFunction(), 300);
  }

  // Gets video
  async get_video() {
    this.activated_route.params.subscribe(async () => {
      this.exercises = this.program.Program_Exercises[this.current_exercise_id].Exercise.real_brightcove_id;
    });

  }

  track_exercise() {

  }

  // Completes exercise for all of today
  completed_all(event) {
    // Prevent user from spam clicking button
    if (event.timeStamp < (this.button_timer + 500)) {
      return;
    } else {
      this.button_timer = event.timeStamp;
    }

    // tslint:disable-next-line:max-line-length
    const logging_left_today: Number = this.program.Program_Exercises[this.current_exercise_id].daily - this.program.Program_Exercises[this.current_exercise_id].Activity_Records.length;
    if (logging_left_today > 0) {
      for (let index = 0; index < logging_left_today; index++) {
        //  tslint:disable-next-line:max-line-length
        this.logging_service.create_activity_record(this.program.Program_Exercises[this.current_exercise_id].id, this.program.Program_Exercises[this.current_exercise_id].reps, this.program.Program_Exercises[this.current_exercise_id].sets)
          .subscribe(() => {
          });
      }

      // Waiting until loop above is finished
      setTimeout(() => {
        this.next_exercise();
        this.snackBar.open('Exercise Completed Today', 'Close', {
          duration: 3000
        });
      }, 500);
    } else {
      this.snackBar.open('Already Completed For Today', 'Close', {
        duration: 3000
      });
      this.next_exercise();
    }
  }

  // Completes exercise once today
  completed_once() {
    // tslint:disable-next-line:max-line-length
    const logging_left_today: Number = this.program.Program_Exercises[this.current_exercise_id].daily - this.program.Program_Exercises[this.current_exercise_id].Activity_Records.length;

    if (logging_left_today > 0) {
      if (this.is_reps_sets && !this.is_heart_rate) {
        // tslint:disable-next-line:max-line-length
        this.logging_service.create_activity_record(this.program.Program_Exercises[this.current_exercise_id].id, this.program.Program_Exercises[this.current_exercise_id].reps, this.program.Program_Exercises[this.current_exercise_id].sets)
          .subscribe(() => {
            this.snackBar.open('Exercise Completed Today', 'Close', {
              duration: 3000
            });
            this.next_exercise();
          });
      } else if (this.is_heart_rate && !this.is_reps_sets) {
        // tslint:disable-next-line:max-line-length
        this.logging_service.create_activity_record(this.program.Program_Exercises[this.current_exercise_id].id, null, null, this.program.Program_Exercises[this.current_exercise_id].hr_max)
          .subscribe(() => {
            this.snackBar.open('Exercise Completed Today', 'Close', {
              duration: 3000
            });
            this.next_exercise();
          });
      }
    } else {
      this.snackBar.open('Already Completed For Today', 'Close', {
        duration: 3000
      });
      this.next_exercise();
    }

  }

  // Opens dialog to let you input how many sets and reps or hr you completed, If canceled do nothing
  could_not_complete() {
    const dialog = this.dialog.open(LoggingDialogComponent, {
      data: {
        Program: this.program,
        CurrentProgram: this.current_exercise_id
      },
      disableClose: true,
      width: '50%',
      height: '55%'

    });

    dialog.backdropClick().subscribe(res => {
      dialog.close();
    });

    dialog.afterClosed().subscribe(res => {
      if (res === 'complete') {
        this.next_exercise();
      }
    });

  }

  // Navigates to next exercise or first exercise if there is no next
  next_exercise() {
    if (this.current_exercise_id + 1 < this.program.Program_Exercises.length) {
      this.update_id(this.current_exercise_id);
      this.router.navigate([`/programs/${this.program_id}/exercise/${this.current_exercise_id + 2}`]);
    } else if (this.program.Program_Exercises.length === 1) {
      this.ngOnInit();
    } else {
      this.current_exercise_id = 0;
      this.update_id(this.current_exercise_id);
      this.router.navigate([`/programs/${this.program_id}/exercise/${this.current_exercise_id + 1}`]);
    }
  }

  // Gets program pdf
  get_program_pdf() {
    this.api_service.get_pdf(`${environment.endpoints.exercise.programs}${this.program_id}/pdf`, this.program);
  }

  // Displays pdf
  get_leaflet_pdf(leaflet) {
    const pdf_id = leaflet.pdf_url.split('/');
    this.api_service.get_pdf('/leafletpdf/' + pdf_id[4], leaflet);
  }

}
