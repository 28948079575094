import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { SharedRoutingModule } from './shared-routing.module';
import { BrandingComponent } from './components/branding/branding.component';
import {IconNavComponent} from './components/icon-nav/icon-nav.component';
import {MatTabsModule, MatSelectModule, MatListModule, MatDialogModule, MatToolbarModule, MatMenuModule, MatGridListModule,
        MatIconModule, MatInputModule, MatFormFieldModule, MatCardModule, MatSliderModule, MatDatepickerModule,
        MatCheckboxModule, MatNativeDateModule, MatSlideToggleModule, MatPaginatorModule, MatSidenavModule,
  MatTooltipModule, MatRadioModule, MatSnackBarModule, MatBadgeModule, MatTableModule,
  MatSortModule} from '@angular/material';
import { CarouselComponent } from './components/carousel/carousel.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { ChartsComponent } from './components/charts/charts.component';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule} from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor, RequestInterceptor } from './interceptors';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { EffortChartComponent } from './components/charts/effort-chart/effort-chart.component';
import { PainChartComponent } from './components/charts/pain-chart/pain-chart.component';
import { ActivityChartComponent } from './components/charts/activity-chart/activity-chart.component';
import { CompletionChartComponent } from './components/charts/completion-chart/completion-chart.component';
import { ComplianceChartComponent } from './components/charts/compliance-chart/compliance-chart.component';
import { ProgramItemComponent } from '@app/modules/exercises/components/program-item/program-item.component';
import { SanitizerPipe } from '@app/shared/pipes/sanitazation.pipe';
import { TruncateTextPipe } from '@app/shared/pipes/truncate.text.pipe';
import { DragAndDropModule, DraggableHelper } from 'angular-draggable-droppable';
import { PrescriptionService } from '@app/shared/services/prescription.service';
import { ExerciseService } from '@app/shared/services';
import {
  SalasoRegisterErrorDialogComponent,
  SalasoRegisterSuccessDialogComponent,
  SalasoEditAccountDialogComponent,
  SalasoErrorEditAccountDialogComponent,
  SalasoConsentDialogComponent,
  SalasoPolicyDialogComponent,
  SalasoNovartisDialogComponent
} from './services/user.service';
import { FilePreviewOverlayComponent } from './components/file-preview-overlay/file-preview-overlay.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { FilePreviewOverlayRef } from './components/file-preview-overlay/file-preview-overlay-ref.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DatePickerUpdatedComponent } from './components/date-picker-updated/date-picker.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { PatientSurveyListComponent } from './components/patient-survey-list/patient-survey-list.component';
import { SurveyAnswersDialogComponent } from './components/survey-answers-dialog/survey-answers-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    MatListModule,
    MatToolbarModule,
    DragScrollModule,
    MatMenuModule,
    MatGridListModule,
    ChartsModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatSliderModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    DragAndDropModule,
    MatSidenavModule,
    MatRadioModule,
    MatTooltipModule,
    MatRadioModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatTableModule,
    MatSortModule,
  ],
  exports: [
    BrandingComponent,
    IconNavComponent,
    MatListModule,
    MatTabsModule,
    DragScrollModule,
    MatMenuModule,
    MatToolbarModule,
    MatGridListModule,
    ChartsComponent,
    ChartsModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatSliderModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    EffortChartComponent,
    PainChartComponent,
    ActivityChartComponent,
    CompletionChartComponent,
    ComplianceChartComponent,
    DatePickerComponent,
    MatCheckboxModule,
    ProgramItemComponent,
    MatPaginatorModule,
    SanitizerPipe,
    TruncateTextPipe,
    DragAndDropModule,
    MatSidenavModule,
    MatRadioModule,
    MatTooltipModule,
    MatRadioModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatTableModule,
    MatSortModule,
    SalasoRegisterErrorDialogComponent,
    SalasoRegisterSuccessDialogComponent,
    SalasoEditAccountDialogComponent,
    SalasoErrorEditAccountDialogComponent,
    SalasoConsentDialogComponent,
    SalasoPolicyDialogComponent,
    SalasoNovartisDialogComponent,
    DatePickerUpdatedComponent,
    SearchBoxComponent,
    PatientSurveyListComponent,
    SurveyAnswersDialogComponent
  ],
  providers: [
    MatTabsModule,
    MatListModule,
    MatToolbarModule,
    DragScrollModule,
    MatMenuModule,
    MatToolbarModule,
    MatGridListModule,
    ChartsModule,
    RouterModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    DragAndDropModule,
    DraggableHelper,
    MatSidenavModule,
    PrescriptionService,
    ExerciseService,
    MatRadioModule,
    MatTooltipModule,
    MatRadioModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatTableModule,
    MatSortModule,
    FilePreviewOverlayRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    }
  ],

  declarations: [
    BrandingComponent,
    IconNavComponent,
    CarouselComponent,
    ChartsComponent,
    EffortChartComponent,
    PainChartComponent,
    ActivityChartComponent,
    CompletionChartComponent,
    ComplianceChartComponent,
    DatePickerComponent,
    ProgramItemComponent,
    SanitizerPipe,
    TruncateTextPipe,
    SalasoRegisterErrorDialogComponent,
    SalasoRegisterSuccessDialogComponent,
    SalasoEditAccountDialogComponent,
    SalasoErrorEditAccountDialogComponent,
    SalasoConsentDialogComponent,
    SalasoPolicyDialogComponent,
    SalasoNovartisDialogComponent,
    FilePreviewOverlayComponent,
    PdfViewerComponent,
    ConfirmDialogComponent,
    DatePickerUpdatedComponent,
    SearchBoxComponent,
    PatientSurveyListComponent,
    SurveyAnswersDialogComponent
  ],
  entryComponents: [
    SalasoNovartisDialogComponent,
    SalasoRegisterErrorDialogComponent,
    SalasoRegisterSuccessDialogComponent,
    SalasoEditAccountDialogComponent,
    SalasoErrorEditAccountDialogComponent,
    SalasoConsentDialogComponent,
    SalasoPolicyDialogComponent,
    FilePreviewOverlayComponent,
    ConfirmDialogComponent,
    SurveyAnswersDialogComponent
  ]
})
export class SharedModule {}
