import {
  Injectable
} from '@angular/core';
import {
  environment
} from '@env/environment';
import {
  ApiService
} from '@app/shared/services/api.service';

/**
 * Service for Questionnaires
 *
 * @export
 * @class QuestionnaireService
 */

@Injectable()
export class QuestionnaireService {

  /**
   * Creates an instance of QuestionnaireService.
   * @param {ApiService} api_service
   * @memberof QuestionnaireService
   */

  constructor(private api_service: ApiService) {}

  /**
   * Create a new questionnaire. Server returns details of creation
   *
   * @private
   * @param {String} name
   * @param {String} description
   * @param {String} link
   * @param {Number} org_id
   * @memberof QuestionnaireService
   */

  private create_questionnaire(name: String, description: String, link: String, org_id: Number) {

    const body = {
      name: name,
      description: description,
      link: link,
      org_id: org_id
    };

    this.api_service.post(`${environment.endpoints.questionnaires.questionnaires}`, body)
      .subscribe((res) => {
      });

  }

  /**
   * Returns a list of questionnaires
   *
   * @private
   * @memberof QuestionnaireService
   */

  public get_questionnaires() {

    this.api_service.get(`${environment.endpoints.questionnaires.questionnaires}`)
      .subscribe((res) => {

      });

  }

  /**
   * Returns a list of questionnaires belonging to an organisation.
   *
   * @private
   * @param {Number} org_id
   * @memberof QuestionnaireService
   */

  public get_org_questionaires(org_id: Number) {

   return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.questionnaires.questionnaires}`);

  }

  /**
   * Returns a single questionnaire based on it's id.
   *
   * @private
   * @param {Number} questionnaire_id
   * @memberof QuestionnaireService
   */

  public get_questionnaire(questionnaire_id: Number) {

    return this.api_service.get(`${environment.endpoints.questionnaires.questionnaires}${questionnaire_id}`);

  }

  /**
   * Create an invitation to complete a questionnaire
   *
   * @private
   * @param {Number} user_id
   * @param {Number} questionnaire_id
   * @param {Number} status
   * @memberof QuestionnaireService
   */

  public create_questionnaire_invitation(user_id: Number, questionnaire_id: Number, status: Number) {

    const body = {
      questionnaire_id: questionnaire_id,
      status: status
    };

    // tslint:disable-next-line:max-line-length
    this.api_service.post(`${environment.endpoints.user.users}${user_id}${environment.endpoints.questionnaires.questionnaire_invitations}`, body)
      .subscribe((res) => {

      });
  }

  /**
   * Get a multiple records of invitations
   *
   * @private
   * @memberof QuestionnaireService
   */

  public get_questionnaire_invitations(user_id: Number) {

   // tslint:disable-next-line:max-line-length
   return this.api_service.get(`${environment.endpoints.user.users}${user_id}${environment.endpoints.questionnaires.questionnaire_invitations}`);
  }

  public get_questionnaire_invitations_filtered(user_id: Number, limit: Number, offset: Number, status: Number) {

    // tslint:disable-next-line:max-line-length
    // return this.api_service.get(`${environment.endpoints.user.users}${user_id}${environment.endpoints.questionnaires.questionnaire_invitations}?order_by=activity&limit=${limit}&offset=${offset}`);
    // tslint:disable-next-line:max-line-length
    return this.api_service.get(`${environment.endpoints.user.users}${user_id}${environment.endpoints.questionnaires.questionnaire_invitations}?status=${status}&limit=${limit}&offset=${offset}`);
  }
  /**
   * Returns a single record of questionnaire invitation from it's id.
   *
   * @private
   * @param {Number} invitation_id
   * @memberof QuestionnaireService
   */

  public get_questionnaire_invitation(invitation_id: Number) {

    this.api_service.get(`${environment.endpoints.questionnaires.questionnaire_invitations}${invitation_id}`)
      .subscribe((res) => {

      });

  }

  /**
   * Create a new Questionnaire Answer
   *
   * @private
   * @param {Number} invitation_id
   * @param {String} answer
   * @param {String} score
   * @memberof QuestionnaireService
   */

  public create_questionnaire_answer(invitation_hash: String, answer: String, score: String, category: String) {

    const body = {
      answer: answer,
      score: score,
      category: category
    };
// POST /api/v2/questionnaire_answers/:invite_hash
    // tslint:disable-next-line:max-line-length
    // this.api_service.post(`${environment.endpoints.questionnaires.questionnaire_invitations}${invitation_hash}${environment.endpoints.questionnaires.answers}`, body)
    //   .subscribe((res) => {

    //   });
    console.log(`${environment.endpoints.questionnaires.questionnaire_answers}${invitation_hash}`);
    this.api_service.post(`${environment.endpoints.questionnaires.questionnaire_answers}${invitation_hash}`, body)
      .subscribe((res) => {

      });

  }

  /**
   *
   *
   * @private
   * @param {Number} invitation_id
   * @memberof QuestionnaireService
   */

  private get_questionnaire_invite_answers(invitation_id: Number) {

    // tslint:disable-next-line:max-line-length
    this.api_service.get(`${environment.endpoints.questionnaires.questionnaire_invitations}${invitation_id}${environment.endpoints.questionnaires.questionnaire_invitations}`)
      .subscribe((res) => {

      });

  }

  /**
   *
   *
   * @private
   * @param {Number} questionnaire_answer_id
   * @memberof QuestionnaireService
   */

  private get_questionnaire_answer(questionnaire_answer_id: Number) {

    this.api_service.get(`${environment.endpoints.questionnaires.questionnaire_answers}${questionnaire_answer_id}`)
      .subscribe((res) => {});

  }

  /**
   *
   *
   * @private
   * @param {String} name
   * @param {String} json
   * @memberof QuestionnaireService
   */

  private create_gen_questionnaire(name: String, json: String) {

    const body = {
      name: name,
      json: json
    };

    this.api_service.post(`${environment.endpoints.questionnaires.gen_questionnaires}`, body)
      .subscribe((res) => {

      });

  }

  /**
   *
   *
   * @private
   * @param {Number} org_id
   * @memberof QuestionnaireService
   */

  private get_org_gen_questionnaire(org_id: Number) {

    this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.questionnaires.gen_questionnaires}`)
      .subscribe((res) => {

      });

  }

  /**
   *
   *
   * @private
   * @param {Number} gen_questionnaire_id
   * @memberof QuestionnaireService
   */

  private get_gen_questionnaire(gen_questionnaire_id: Number) {

    this.api_service.get(`${environment.endpoints.questionnaires.gen_questionnaires}${gen_questionnaire_id}`)
      .subscribe((res) => {

      });

  }

  /**
   *
   *
   * @private
   * @param {Number} physio_id
   * @memberof QuestionnaireService
   */

  private get_physio_gen_questionnaires(physio_id: Number) {

    // tslint:disable-next-line:max-line-length
    this.api_service.get(`${environment.endpoints.physio.physios}${physio_id}${environment.endpoints.questionnaires.gen_questionnaires}`)
      .subscribe((res) => {

      });

  }

  /**
   *
   *
   * @private
   * @param {Number} user_id
   * @param {Number} gen_questionnaire_id
   * @param {Number} status
   * @memberof QuestionnaireService
   */

  private create_gen_questionnaire_invitation(user_id: Number, gen_questionnaire_id: Number, status: Number) {

    const body = {
      gen_questionnaire_id: gen_questionnaire_id,
      status: status
    };

    // tslint:disable-next-line:max-line-length
    this.api_service.post(`${environment.endpoints.user.users}${user_id}${environment.endpoints.questionnaires.gen_questionnaire_invitations}`, body)
      .subscribe((res) => {

      });

  }

  /**
   *
   *
   * @private
   * @param {Number} user_id
   * @memberof QuestionnaireService
   */

  private get_users_gen_questionnaire_invitations(user_id: Number) {

    // tslint:disable-next-line:max-line-length
    this.api_service.get(`${environment.endpoints.user.users}${user_id}${environment.endpoints.questionnaires.gen_questionnaire_invitations}`)
      .subscribe((res) => {

      });

  }

  /**
   *
   *
   * @private
   * @param {Number} gen_questionnaire_invitation
   * @memberof QuestionnaireService
   */

  private get_gen_questionnaire_invitation(gen_questionnaire_invitation: Number) {

    // tslint:disable-next-line:max-line-length
    this.api_service.get(`${environment.endpoints.questionnaires.gen_questionnaire_invitations}${gen_questionnaire_invitation}`)
      .subscribe((res) => {

      });

  }

  /**
   *
   *
   * @private
   * @param {Number} gen_questionnaire_id
   * @param {string} answer
   * @memberof QuestionnaireService
   */

  private add_gen_questionnaire_answer(gen_questionnaire_id: Number, answer: string) {

    const body = {
      answer: answer
    };

    // tslint:disable-next-line:max-line-length
    this.api_service.post(`${environment.endpoints.questionnaires.gen_questionnaire_invitations}${gen_questionnaire_id}${environment.endpoints.questionnaires.gen_answers}`, body)
      .subscribe((res) => {

      });

  }

  /**
   *
   *
   * @private
   * @param {Number} gen_questionnaire_invitation_id
   * @memberof QuestionnaireService
   */

  private get_gen_answers_questionnaire_invitation(gen_questionnaire_invitation_id: Number) {

    // tslint:disable-next-line:max-line-length
    this.api_service.get(`${environment.endpoints.questionnaires.gen_questionnaire_invitations}${gen_questionnaire_invitation_id}${environment.endpoints.questionnaires.gen_answers}`)
      .subscribe((res) => {

      });

  }

  /**
   *
   *
   * @private
   * @param {Number} gen_questionnaire_answer_id
   * @memberof QuestionnaireService
   */

  private get_gen_questionnaire_answer(gen_questionnaire_answer_id: Number) {

    this.api_service.get(`${environment.endpoints.questionnaires.gen_questionnaire_answers}${gen_questionnaire_answer_id}`)
      .subscribe((res) => {

      });

  }

}
