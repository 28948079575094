import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RouterGaurdService } from '@app/shared/gaurds/router-gaurd.service';
import { TermsComponent } from './modules/static/terms/terms.component';
import { PrivacyPolicyComponent} from './modules/static/privacy-policy/privacy-policy.component';
import { CompleteSurveyComponent } from './modules/survey/components/complete-survey/complete-survey.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth',
    canActivate: [RouterGaurdService],
    data: {
      permission: {
        only: [0],
        redirectTo: 'users'
      }
    }
  },
  {
    path: 'auth',
    loadChildren: 'app/modules/authentication/authentication.module#AuthenticationModule'
  },
  {
    path: 'programs',
    loadChildren: 'app/modules/exercises/exercise.module#ExerciseModule'
  },
  {
    path: 'account',
    loadChildren: 'app/modules/account/account.module#AccountModule'
  },
  {
    path: 'users',
    loadChildren: 'app/modules/patient_management/patient_management.module#PatientManagementModule'
  },
  {
    path: 'practitioners',
    loadChildren: 'app/modules/practitioners/practitioners.module#PractitionersModule'
  },
  {
    path: 'surveys',
    loadChildren: 'app/modules/survey/survey.module#SurveyModule'
  },
  {
    path: 'exercises',
    loadChildren: 'app/modules/prescription/prescription.module#PrescriptionModule'
    // canActivate: [PractitionerGaurdService]
  },
  {
    path: 'orgs',
    loadChildren: 'app/modules/organisation/organisation.module#OrganisationModule'
  },
  {
    path: 'questionnaires',
    loadChildren: 'app/modules/questionnaires/questionnaires.module#QuestionnaireModule'
  },
  {
  path: 'terms-of-use',
  component: TermsComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'q/i/:inviteId/r/:respondentId',
    component: CompleteSurveyComponent,
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
