import { Component, OnInit, Input } from '@angular/core';
import { LoggingService } from '@app/shared/services';
import { UserCompletion } from '@app/shared/models/user_completion.model';
import * as moment from 'moment';

@Component({
  selector: 'salaso-completion-chart',
  templateUrl: './completion-chart.component.html',
  styleUrls: ['./completion-chart.component.scss']
})
export class CompletionChartComponent implements OnInit {
  // holding data from services
  user_completion: UserCompletion;
  @Input() user_id: Number;

  // dates
  start_date: Date = new Date();
  end_date: Date = new Date();

  // chart data
  chartOptions: any;
  chartLabels: any = [];
  chartType: String;
  chartData: any = [];

  // Checking if chart is ready
  isChartReady = false;

  constructor(private logging_service: LoggingService) {
    // Getting the start date, start date is today's date minus 30 days
    this.start_date.setDate(this.end_date.getDate() - 29);
  }

  ngOnInit() {
    this.get_logging_service();
  }

  // Getting user completion data from date range
  get_logging_service() {
    /**
         * @param {Number} user_id
         * @param {String} start_date
         * @param {String} end_date
         * @memberof CompletionChartComponent
         */
    this.logging_service.get_user_completion(this.user_id, this.start_date.toISOString(), this.end_date.toISOString())
      .subscribe((res: UserCompletion) => {
        this.user_completion = res;
        this.initChart();
      });
  }

  // Initializing chart
  public initChart() {
    // Chart Type
    this.chartType = 'line';

    // Chart Labels
    this.chartLabels = this.getDates(this.user_completion.date_range.from, this.user_completion.date_range.to);

    // Chart Data
    // Gets each program and pushes to chartData, then gets each completion inside each program and pushes to data
    if (this.user_completion.programs.length > 0) {
      this.user_completion.programs.forEach((program, i) => {
        this.chartData.push({
          label: [program.name || 'Program ' + (i + 1)],
          data: [],
          fill: false,
          pointRadius: 5,
          pointHoverRadius: 6,
          showLine: true,
          responsive: true
        });
        if (!program.Program_Exercises[0]) {
          return;
        }
        this.chartLabels.forEach((label, j) => {
          const dates = Object.entries(program.complete);
          const selectedDates = dates.filter(x => moment(x[0]).format('DD/MM') === label)[0];
          if (selectedDates) {
            const completion = selectedDates[1] as number;
            this.chartData[i].data.push({
              x: label,
              y: Math.round(completion)
            });
          } else {
            this.chartData[i].data.push({
              x: label,
              y: 0
            });
          }
        });
      });
    } else {
      this.chartData = [{
        label: '',
        data: []
      }];
    }

    // Chart Options
    this.chartOptions = {
      responsive: true,
      // Tooltip when hovering over point
      tooltips: {
        callbacks: {
          title: function (t, d) {
            return d.datasets[t[0].datasetIndex].label;
          },
          label: function (t, d) {
            return d.datasets[t.datasetIndex].label + ': (Date: ' + d.datasets[t.datasetIndex].data[t.index].x +
              ', Completion:  ' + t.yLabel + '%)';
          }
        }
      },
      // X axis and Y axis scales, put Y axes to 0-100%
      scales: {
        yAxes: [{
          type: 'linear',
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            stepSize: 10,
            callback: function (tick) {
              return tick.toString() + '%';
            },
            fontColor: '#aaa'
          }
        }],
        xAxes: [{
          fontColor: '#aaa'
        }]
      },
      // Legend positioned at bottom, lets you toggle each program on or off
      legend: {
        display: true,
        position: 'bottom'
      },
      // Point shape is circle
      elements: {
        point: {
          pointStyle: 'circle'
        }
      },
      // Individual hovering of each point
      hover: {
        mode: 'x'
      },
      maintainAspectRatio: false
    };

    // Chart is now ready to be displayed
    this.isChartReady = true;
  }

  // Event when chart is clicked
  public chartClicked(e: any): void {

  }

  // Event when chart is hovered
  public chartHovered(e: any): void {

  }

  // Gets array of two selected dates
  // Used to get the values of X axis scale
  getDates(startDate, stopDate) {
    const dateArray = [];
    let currentDate = moment(startDate);
    dateArray.push(moment(currentDate).format('DD/MM'));
    stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      currentDate = moment(currentDate).add(1, 'days');
      dateArray.push(moment(currentDate).format('DD/MM'));
    }
    return dateArray;
  }
}
