import {
  Component,
  OnInit,
  Inject
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  Program
} from '@app/shared/models';
import {
  LoggingService
} from '@app/shared/services/logging.service';
import { Router } from '@angular/router';

@Component({
  selector: 'salaso-compliance-logger',
  templateUrl: './compliance-logger.component.html',
  styleUrls: ['./compliance-logger.component.scss']
})
export class ComplianceLoggerComponent implements OnInit {
  compliance_form: FormGroup;
  activity_id: Number;
  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, private logging_service: LoggingService, private form_builder: FormBuilder, public dialogRef: MatDialogRef < ComplianceLoggerComponent > , @Inject(MAT_DIALOG_DATA) public data: any) {

    this.compliance_form = this.form_builder.group({
      'effort': [0, Validators.required],
      'pain': [0, Validators.required],
      'score': [0, Validators.required]
    });

    this.logging_service.get_user_activity_goal(this.data.program.user_login_id)
    .subscribe(res => {
      this.activity_id = res.activity.id;
    });
  }


  ngOnInit() {}

  confirmSelection() {
    this.submit();
    this.dialogRef.close();
  }

  submit() {

    this.logging_service.create_program_effort(this.data.program_id, this.compliance_form.get('effort').value)
      .subscribe(res => {

      });

    this.logging_service.create_user_pain(this.data.program.user_login_id, this.compliance_form.get('pain').value)
      .subscribe(res => {

      });

      this.logging_service.create_activity_score(this.activity_id, this.compliance_form.get('score').value)
      .subscribe(res => {

      });

      this.router.navigate(['/programs/stats']);


    }

  // Formats label
  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }
    if (value >= 1) {
      return Math.round(value) + '/10';
    }

    return value;
  }


}
