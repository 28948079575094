import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import {
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { LoggingService } from '@app/shared/services/logging.service';
import { ProgramExercise } from '@app/shared/models';
import { Router } from '@angular/router';

@Component({
  selector: 'salaso-logging-dialog',
  templateUrl: './logging-dialog.component.html',
  styleUrls: ['./logging-dialog.component.scss']
})
export class LoggingDialogComponent implements OnInit {

  logging_form: FormGroup;
  program: any;
  current_id: number;
  is_reps_sets: Boolean;
  is_heart_rate: Boolean;
  reps: number;
  sets: number;
  hr_max: number;
  hr_min: number;
  program_exercise: ProgramExercise;

  value_error_message: string;

  // tslint:disable-next-line:max-line-length
  constructor(private logging_service: LoggingService, private form_builder: FormBuilder, public dialogRef: MatDialogRef<LoggingDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private snackbar: MatSnackBar, private router: Router) {

    this.reps = 0;
    this.sets = 0;
    this.hr_max = 0;
    this.hr_min = 0;

    this.program = this.data.Program;
    this.current_id = this.data.CurrentProgram;
    this.logging_form = this.form_builder.group({});
    this.program_exercise = this.program.Program_Exercises[this.current_id];

    this.check_type();
  }

  ngOnInit() {

  }

  check_type() {
    if (this.program_exercise.hr_min && this.program_exercise.hr_max && this.program_exercise.reps && this.program_exercise.sets) {
      this.is_reps_sets = true;
      this.is_heart_rate = true;
    } else if (this.program_exercise.reps && this.program_exercise.sets) {
      this.is_reps_sets = true;
      this.is_heart_rate = false;
    } else if (this.program_exercise.hr_min && this.program_exercise.hr_max) {
      this.is_heart_rate = true;
      this.is_reps_sets = false;
    } else {
      this.is_reps_sets = false;
      this.is_heart_rate = false;
    }
  }

  confirmSelection() {
    // tslint:disable-next-line:max-line-length
    const logging_left_today: Number = this.program.Program_Exercises[this.current_id].daily - this.program.Program_Exercises[this.current_id].Activity_Records.length;

    if (logging_left_today > 0) {
      if (this.is_reps_sets && !this.is_heart_rate) {
        this.logging_service.create_activity_record(this.program.Program_Exercises[this.current_id].id, this.reps, this.sets)
          .subscribe((res: any) => {

          });
      } else if (this.is_heart_rate && !this.is_reps_sets) {
        this.logging_service.create_activity_record(this.program.Program_Exercises[this.current_id].id, null, null, this.hr_max)
          .subscribe((res: any) => {

          });
      }
      this.snackbar.open('Exercise Completed', 'Close', {
        duration: 3000
      });
    } else {
      this.snackbar.open('Already Completed For Today', 'Close', {
        duration: 3000
      });
    }
    this.dialogRef.close('complete');
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  // Reps/Sets

  minus_reps() {
    if (this.reps <= 0) {
      this.value_error_message = 'Value cannot be 0 or less';
    } else {
      this.value_error_message = undefined;
      this.reps = this.reps - 1;
    }
  }

  plus_reps() {
    if (this.reps >= this.program.Program_Exercises[this.current_id].reps) {
      this.value_error_message = `Value cannot over ${this.program.Program_Exercises[this.current_id].reps}`;
    } else {
      this.value_error_message = undefined;
      this.reps = this.reps + 1;
    }
  }

  minus_sets() {
    if (this.sets <= 0) {
      this.value_error_message = 'Value cannot be 0 or less';
    } else {
      this.value_error_message = undefined;
      this.sets = this.sets - 1;
    }

  }

  plus_sets() {
    if (this.sets >= this.program.Program_Exercises[this.current_id].sets) {
      this.value_error_message = `Value cannot over ${this.program.Program_Exercises[this.current_id].sets}`;
    } else {
      this.value_error_message = undefined;
      this.sets = this.sets + 1;
    }
  }

  // Min_hr/Max_hr

  minus_hr_min() {
    if (this.hr_min <= 0) {
      this.value_error_message = 'Value cannot be 0 or less';
    } else {
      this.value_error_message = undefined;
      this.hr_min -= 1;
    }
  }

  plus_hr_min() {
    if (this.hr_min >= this.program.Program_Exercises[this.current_id].hr_min) {
      this.value_error_message = `Value cannot over ${this.program.Program_Exercises[this.current_id].hr_min}`;
    } else {
      this.value_error_message = undefined;
      this.hr_min += 1;
    }
  }

  minus_hr_max() {
    if (this.hr_max <= 0) {
      this.value_error_message = 'Value cannot be 0 or less';
    } else {
      this.value_error_message = undefined;
      this.hr_max -= 1;
    }

  }

  plus_hr_max() {
    if (this.hr_max >= this.program.Program_Exercises[this.current_id].hr_max) {
      this.value_error_message = `Value cannot over ${this.program.Program_Exercises[this.current_id].hr_max}`;
    } else {
      this.value_error_message = undefined;
      this.hr_max += 1;
    }
  }
}
