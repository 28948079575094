import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

export enum SearchContexts {
    PHYSIO = 'physio',
    USER = 'user'
}

@Component({
    selector: 'salaso-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit, OnDestroy {

    @Input() multiContentSearch = false;
    @Input() searchTitle;
    @Input() placeholder;
    @Input() buttonTitle;
    @Input() searchTerm;

    @Output() updateFilteredList: EventEmitter<any> = new EventEmitter();
    @Output() clickButton: EventEmitter<any> = new EventEmitter();
    @Output() updateCurrentSearchContext: EventEmitter<SearchContexts> = new EventEmitter();

    defaultSearchContext = SearchContexts.USER;
    currentSeachContext;

    ngOnInit() {
        this.currentSeachContext = this.defaultSearchContext;
        this.updateCurrentSearchContext.emit(this.defaultSearchContext);
    }

    handleContextSelectionChange() {
        this.updateCurrentSearchContext.emit(this.currentSeachContext);
    }


    search() {
        this.updateFilteredList.emit(this.searchTerm);
    }

    handleClickButton() {
        this.clickButton.emit(true);
    }

    clearSearch() {
        this.searchTerm = '';
        this.search();
    }

    ngOnDestroy() {
        this.searchTerm = '';
        this.currentSeachContext = '';
    }
}
