import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

interface ConfirmDialogData {
    header: string;
    message: string;
    button1: string;
    button2: string;
    confirmDeletion?: boolean;
}

@Component({
    selector: 'salaso-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    confirmDeletionInput: string;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
    ) { }

    handleCloseDialog(reason) {
        this.dialogRef.close(reason);
    }
}