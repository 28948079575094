import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { ExerciseTestComponent } from './pages/exercise-test/exercise-test.component';
import { ExerciseRoutingModule } from './exercise.routing';
import { PatientComponent } from './pages/patient/patient.component';
import { ProgramListComponent } from './components/program-list/program-list.component';
import { UserTabsComponent } from './components/user-tabs/user-tabs.component';
import { MatIconModule } from '@angular/material/icon';
import { ExerciseDetailsComponent } from './pages/exercise-details/exercise-details.component';
import { StatsComponent } from './pages/stats/stats.component';
import { ActivityComponent } from './pages/activity/activity.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoggingDialogComponent } from './components/logging-dialog/logging-dialog.component';
import { ComplianceLoggerComponent } from './components/compliance-logger/compliance-logger.component';
import { ActivityContainerComponent } from './components/activity-container/activity-container.component';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
  RouterGaurdService
} from '@app/shared/gaurds/router-gaurd.service';
import { PatientSurveysPageComponent } from './pages/patient-surveys-page/patient-surveys-page.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ExerciseRoutingModule,
        MatIconModule,
        HttpClientModule,
        HttpModule,
        FormsModule,
        ReactiveFormsModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        CommonModule,
    ],
  // tslint:disable-next-line:max-line-length
  providers: [DatePipe, RouterGaurdService],


    declarations: [
    PatientComponent,
    ProgramListComponent,
    UserTabsComponent,
    LoggingDialogComponent,
    ExerciseTestComponent,
    ExerciseDetailsComponent,
    StatsComponent,
    ActivityComponent,
    LoggingDialogComponent,
    PatientSurveysPageComponent,
    ComplianceLoggerComponent,
    ActivityContainerComponent],
    entryComponents: [LoggingDialogComponent, ComplianceLoggerComponent],
})
export class ExerciseModule { }
