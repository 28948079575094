import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SurveyService } from '@app/modules/survey/survey.service';
import { SurveyInviteAnswers } from '@app/shared/models/survey.model';

@Component({
    selector: 'salaso-survey-answers-dialog',
    templateUrl: './survey-answers-dialog.component.html',
    styleUrls: ['./survey-answers-dialog.component.scss']
})
export class SurveyAnswersDialogComponent implements OnInit {
    surveyAnswers: SurveyInviteAnswers[];

    surveyId: string;
    isUserGroupAnswers = false;
    surveyName = '';
    userName = '';
    category = '';
    subScore = 0;
    score = 0;
    selectedIndex = 0;

    constructor(
        private surveyService: SurveyService,
        public dialogRef: MatDialogRef<SurveyAnswersDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) { }

    ngOnInit() {
        this.checkDataForSurveyAnswers();
    }

    checkDataForSurveyAnswers() {
        if (Array.isArray(this.data)) {
            this.isUserGroupAnswers = true;
            this.getSurveyDetailsForUserGroup();
            this.surveyName = this.data[0].Survey.name;
        } else {
            console.log(this.data)
            this.isUserGroupAnswers = false;
            this.surveyAnswers = JSON.parse(this.data.Survey_Answer.answers);
            this.surveyName = this.data.Survey.name;
            this.surveyId = this.data.id;
            this.score = this.data.score;
            this.subScore = this.data.subScore;
            this.category = this.data.category;
        }
    }

    getSurveyDetailsForUserGroup() {
        const userGroupSurvey = this.data[this.selectedIndex];

        this.surveyAnswers = userGroupSurvey.Survey_Answer ? JSON.parse(userGroupSurvey.Survey_Answer.answers) : [];
        this.userName = `${userGroupSurvey.Login.first_name} ${userGroupSurvey.Login.last_name}`;
        this.surveyId = userGroupSurvey.id;
        this.score = userGroupSurvey.score;
        this.subScore = userGroupSurvey.subScore;
        this.category = userGroupSurvey.category;
    }

    handlePreviousUserAnswers() {
        if (this.selectedIndex > 0) {
            this.selectedIndex -= 1;
            this.getSurveyDetailsForUserGroup();
        }
    }

    handleNextUserAnswers() {
        if (this.selectedIndex < this.data.length - 1) {
            this.selectedIndex += 1;
            this.getSurveyDetailsForUserGroup();
        }
    }

    handleDownloadAnswers() {
        this.surveyService.downloadSurveyAnswers(this.surveyId, { name: this.surveyName });
    }
}
