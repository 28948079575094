import { OverlayRef } from '@angular/cdk/overlay';

export class FilePreviewOverlayRef {
    overlayRef: OverlayRef;

    constructor() { }

    close() {
        this.overlayRef.dispose();
    }
}
