import {
  Injectable
} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {
  UserService
} from '@app/shared/services/user.service';
import {
  Observable,
} from 'rxjs';


/**
 *
 *
 * @export
 * @class TokenInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

/**
 *Creates an instance of TokenInterceptor.
 * @param {UserService} user_service
 * @memberof TokenInterceptor
 */

constructor(private user_service: UserService) {}

/**
 *
 *
 * @param {HttpRequest <any>} request
 * @param {HttpHandler} next
 * @returns {Observable <HttpEvent<any>>}
 * @memberof TokenInterceptor
 */

intercept(request: HttpRequest <any> , next: HttpHandler): Observable <HttpEvent<any>> {

    if (localStorage.getItem('access_token')) {
      request = request.clone({
        setHeaders: {
          'x-access-token': this.user_service.get_token('access_token')
        }
      });
    }

    return next.handle(request);

  }


}
