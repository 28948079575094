import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {TermsComponent} from '@app/modules/static/terms/terms.component';
import { RouterGaurdService } from '@app/shared/gaurds/router-gaurd.service';


const routes: Routes = [
  {
    path: '',
    component: TermsComponent,
    canActivate: [RouterGaurdService],
    data: {
      permission: {
        only: [5],
        redirectTo: 'exercises'
      }
    }

  },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)]
})
export class StaticRoutingModule { }
