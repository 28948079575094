import { Component, HostListener, OnInit } from '@angular/core';
import { FilePreviewOverlayRef } from './file-preview-overlay-ref.component';
import { saveAs } from 'file-saver/src/FileSaver';

@Component({
    selector: 'salaso-file-preview-overlay',
    templateUrl: './file-preview-overlay.component.html',
    styleUrls: ['./file-preview-overlay.component.scss']
})
export class FilePreviewOverlayComponent implements OnInit {

    fileName = '';
    file: File;

    @HostListener('keydown.ESC', ['$event'])
    onKeyDown() {
        this.closeOverlay();
    }

    constructor(private overlayRef: FilePreviewOverlayRef) { }

    ngOnInit() {
    }

    handleDownloadFile() {
        saveAs(this.file, this.fileName);
    }

    closeOverlay() {
        this.overlayRef.close();
        this.fileName = '';
        this.file = undefined;
    }
}
