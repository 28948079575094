import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SurveyService } from '@app/modules/survey/survey.service';
import { SurveyInvite, PatientOrgSurvey } from '@app/shared/models/survey.model';
import { SubSink } from 'subsink';
import { UserService } from '@app/shared/services';
import { PaginationData } from '@app/shared/models';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'salaso-patient-surveys-page',
    templateUrl: './patient-surveys-page.component.html',
    styleUrls: ['./patient-surveys-page.component.scss']
})
export class PatientSurveysPageComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) matPaginator: MatPaginator;

    surveyInvites: SurveyInvite[];
    patientOrgSurveys: PatientOrgSurvey[];
    surveyType: 'assigned' | 'completed' | 'public' = 'public';

    userId;
    orgId;

    isCompletedSurveys: 0 | 1 = 0;

    paginationData: PaginationData;
    pageSizeOptions = [10, 20, 50, 100];

    subs = new SubSink();

    constructor(private surveyService: SurveyService, private userService: UserService, private snackBar: MatSnackBar) { }

    ngOnInit() {
        this.getIdsForUser();
        this.getSurveyInvites();
    }

    getIdsForUser() {
        this.userId = this.userService.get_user_id();
        this.orgId = this.userService.get_user_org_id();
    }

    getSurveyInvites(limit = 10, offset = 0, isComplete = 0) {
        this.subs.sink = this.surveyService.getSurveyInvites(this.userId, limit, offset, isComplete).subscribe(
            (response) => this.onGetInviteSurveysSuccess(response, isComplete),
            (error) => this.onError(error)
        );
    }

    onGetInviteSurveysSuccess(response, isCompleted) {
        this.patientOrgSurveys = undefined;
        this.isCompletedSurveys = isCompleted;
        this.surveyInvites = response.surveyInvites;
        this.paginationData = response.paginationData;
    }

    getPatientOrgSurveys(limit = 10, offset = 0) {
        this.subs.sink = this.surveyService.getPatientOrgSurveys(this.orgId, limit, offset).subscribe(
            (response) => this.onGetPatientOrgSurveysSuccess(response),
            (error) => this.onError(error)
        );
    }

    onGetPatientOrgSurveysSuccess(response) {
        this.surveyInvites = undefined;
        this.patientOrgSurveys = response.surveys;
        this.paginationData = response.paginationData;
    }

    handleSurveyTypeChange(event) {
        this.surveyType = event;
        this.resetPaginator();
        this.getSurveysBasedOnSurveyType();
    }

    getSurveysBasedOnSurveyType(limit = 10, offset = 0) {
        if (this.surveyType === 'assigned') {
            this.getSurveyInvites(limit, offset);
        }

        if (this.surveyType === 'completed') {
            this.getSurveyInvites(limit, offset, 1);
        }

        if (this.surveyType === 'public') {
            this.getPatientOrgSurveys(limit, offset);
        }
    }

    handleStartSurvey(survey: PatientOrgSurvey) {
        this.subs.sink = this.surveyService.createPatientOrgSurveyInvite(survey.id, this.userId, this.orgId).subscribe(
            (response) => this.onCreateSurveyInviteSuccess(response),
            (error) => this.onError(error)
        );
    }

    onCreateSurveyInviteSuccess(response) {
        window.location.href = response.surveyInvite.Survey.baseUrl + response.surveyInvite.url;
    }

    handleRemovePatientOrgSurveyInvite(inviteId: string) {
        this.subs.sink = this.surveyService.removePatientOrgSurveyInvite(inviteId, this.userId).subscribe(
            () => this.onRemovePatientOrgSurveyInviteSuccess(),
            (error) => this.onError(error)
        );
    }

    onRemovePatientOrgSurveyInviteSuccess() {
        this.snackBar.open('Survey invite revoked. You may start the survey again under public surveys', 'Close', {
            duration: 5000
        });

        this.resetPaginator();
        this.getSurveysBasedOnSurveyType();
    }

    onPaginationChange(event) {
        const limit = event.pageSize;
        const offset = event.pageIndex * limit;

        this.getSurveysBasedOnSurveyType(limit, offset);
    }

    resetPaginator() {
        if (this.matPaginator) {
            this.matPaginator.firstPage();
        }
    }

    onError(error) {
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
