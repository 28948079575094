// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   api_url: `https://nwvpn.salaso.com/gateway/api/v2/`,
  image_url: `https://nwvpn.salaso.com/gateway/`,
  //api_url: `http://localhost:3009/api/v2`,
  //image_url: `http://localhost:3009/`,
  dateFormat: 'MMM dd yyyy',
  graphDateFormat: 'MMM DD',
  dateTimeFormat: 'MMM dd yyyy HH:mm',
  altTextFormat: 'MMMM DD',
  endpoints: {
    admin: {
      admin: `/admins/`,
    },
    auth: {
      login: `/login/`,
      logout: `/logout/`,
      refresh: `/refresh/`,
      register: `/register/`,
      is_multi_user: '/single_or_multi_account/',
      password_reset: '/password_reset/',
      reset_password: '/reset_password/',
      change_password: '/password/',
      token_exchange: '/tokenexchange/',
      resend_invite: '/resend_invite/'
    },
    physio: {
      physios: `/physios/`
    },
    admins: {
      admins: `/admins/`
    },
    org: {
      orgs: `/orgs/`
    },
    user: {
      users: `/users/`,
      login_consents: `/login_consents/`
    },
    exercise: {
      exercises: `/exercises/`,
      programs: `/programs/`,
      templates: `/templates/`,
      deactivate: `/deactivate/`,
      activate: `/activate/`,
      drafttemplate: '/draft_templates/'
    },
    questionnaires: {
      questionnaires: `/questionnaires/`,
      questionnaire_invitations: `/questionnaire_invitations/`,
      answers: `/answers/`,
      questionnaire_answers: `/questionnaire_answers/`,
      gen_answers: `/gen_answers`,
      gen_questionnaires: `/gen_questionnaires/`,
      gen_questionnaire_invitations: `/gen_questionnaire_invitations/`,
      gen_questionnaire_answers: `/gen_questionnaire_answers/`
    },
    logging: {
      programs: `/programs/`,
      program_exercises: `/program_exercises/`,
      activity_record: `/activity_record/`,
      activity_score: `/activity_score/`,
      effort: `/effort/`,
      pain: `/pain/`,
      activity: `/activity/`,
      activities: `/activities/`,
      score: `/score/`,
      completion: `/completion/`,
      compliance: `/compliance/`,
      latest: `latest/`
    },
    survey: {
      surveys: '/surveys/',
      assignToOrg: '/assign_to_org/',
      removeFromOrg: '/remove_from_org/',
      surveyInvites: '/survey_invites/',
      patientSurveys: '/patient_surveys/',
      answers: '/answers',
      complete: '/complete/',
      patientOrgSurveys: '/patient_org_surveys',
      invite: '/invite',
      pdf: '/pdf',
      surveyGraph: '/survey_graph/',
      surveysAssigned: '/surveys_assigned',
    },
    exercises: {
      bright_cove: `https://edge.api.brightcove.com/playback/v1/accounts/1982327620001/videos/`
    },
    filters: {
      template: {
        categories: `/filters/templates/categories/`,
        sub_categories: `/filters/templates/sub_categories/`
      },
      exercise: {

      }
    },
    leaflet: {
      leaflets: `/leaflets/`,
      user_leaflets: `/user_leaflets/`
    },
    allscripts: {
      tw: 'dashboard/physios/'
    }
  },
  hmr: false
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
