import {
  Injectable
} from '@angular/core';
import {
  environment
} from '@env/environment';
import {
  ApiService
} from '@app/shared/services/api.service';
import { UserService } from '@app/shared/services/user.service';

/**
 *
 *
 * @export
 * @class PhysioService
 */

@Injectable()
export class PhysioService {

  /**
   *Creates an instance of PhysioService.
   * @param {ApiService} api_service
   * @memberof PhysioService
   */

  constructor(private api_service: ApiService, private user_service: UserService) {}

  /**
   *
   *
   * @private
   * @memberof PhysioService
   */

  private get_many_physios() {

    this.api_service.get(`${environment.endpoints.physio.physios}`)
      .subscribe((res) => {

      });

  }

  /**
   *
   *
   * @private
   * @param {Number} org_id
   * @param {String} email
   * @param {String} first_name
   * @param {String} last_name
   * @param {String} pin
   * @param {Number} failed_pin_attempts
   * @param {Number} paid
   * @param {Number} paid_bank
   * @param {Number} paid_plan
   * @param {Number} paid_people
   * @param {Number} paid_stripe
   * @param {String} api_id
   * @param {Number} gp
   * @param {Number} trial
   * @param {Number} nostats
   * @param {Number} reminders
   * @param {Number} bounceback
   * @param {Number} deactivated
   * @param {Number} failed_logins
   * @memberof PhysioService
   */

  // tslint:disable-next-line:max-line-length
  public create_new_physio(org_id: Number, email: String, first_name: String, last_name: String) {

    const body = {
      org_id: org_id,
      email: email,
      first_name: first_name,
      last_name: last_name,
    };

    return this.api_service.post(`${environment.endpoints.physio.physios}`, body);

  }

  /**
   *
   *
   * @private
   * @param {Number} physio_id
   * @memberof PhysioService
   */

  public get_physio(physio_id: Number) {

    return this.api_service.get(`${environment.endpoints.physio.physios}${physio_id}`);

  }

  /**
   *
   *
   * @private
   * @param {Number} org_id
   * @memberof PhysioService
   */

  private get_org_physio(org_id: Number) {

    this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.physio.physios}`)
      .subscribe((res) => {
      });

  }

  /**
   *
   * @param physio_id Only need physio_id if an org admin is getting physioUsers
   * @param params paramaters for querying
   */
  public get_physio_users (physio_id?: Number, params?: any) {
    console.log(physio_id, params);
    if (!physio_id && !params) {
      console.log('1');
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.physio.physios}${this.user_service.get_user_id()}${environment.endpoints.user.users}?order_by=activity`);
    } else {
      console.log('2');
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.physio.physios}${physio_id}${environment.endpoints.user.users}${params}`);
    }
  }

  public get_tw_physio_users(physio_id?: Number, params?: any) {
    if (!physio_id && !params) {
      console.log('13');
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.allscripts.tw}${params}`);
    } else {
      console.log('2');
      // tslint:disable-next-line:max-line-length
      return this.api_service.get(`${environment.endpoints.physio.physios}${physio_id}${environment.endpoints.user.users}${params}`);
    }
  }

  public get_single_user (user_id) {
    return this.api_service.get(`${environment.endpoints.user.users}${user_id}`);
  }

  public get_org_users(org_id, limit, offset, search?) {
    // tslint:disable-next-line:max-line-length
    console.log(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.user.users}?limit=${limit}&offset=${offset}&search=${search || ''}`);

    // tslint:disable-next-line:max-line-length
    return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.user.users}?limit=${limit}&offset=${offset}&user_like=${search || ''}`);
  }

  public assign_to_physio(user_id, physio_id) {
    const body = {};
    return this.api_service.post(`${environment.endpoints.user.users}${user_id}${environment.endpoints.physio.physios}${physio_id}`, body);
  }

  public release_from_physio(user_id, physio_id) {
    return this.api_service.delete(`${environment.endpoints.user.users}${user_id}${environment.endpoints.physio.physios}${physio_id}`, {});
  }

  /**
   *
   * @param {Object} physio_id id of physio
   * @param {Number} physio physio object being updated
   * @memberof PhysioService
   */
  public update_physio(physio_id: Number, physio: Object) {
    return this.api_service.put(`${environment.endpoints.physio.physios}${physio_id}`, physio);
  }

}
