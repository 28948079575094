import { Component, OnInit, Input, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { UserService, ProgramService, ApiService } from '@app/shared/services';
import { Program } from '@app/shared/models';
import { environment } from '@env/environment';
import { DragScrollDirective } from 'ngx-drag-scroll';
import { ActivatedRoute, Router } from '@angular/router';
import { PageEvent, MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import { PrescriptionService } from '@app/shared/services/prescription.service';

@Component({
  selector: 'salaso-program-item',
  templateUrl: './program-item.component.html',
  styleUrls: ['./program-item.component.scss']
})
export class ProgramItemComponent implements OnInit, AfterViewInit {

  @Input() user_program: Program;
  @Input() index: number;

  user_id: Number;
  user_role: Number;
  image_endpoint: String = environment.image_url;
  pick_ng_class: String;
  isPhysio: boolean;
  isMa: boolean;
  isChecked: boolean;
  progress: Number = 0;

  patient_id: Number;

  is_dashboard: false;

  @ViewChildren(DragScrollDirective) ds: QueryList<DragScrollDirective>;

  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, private prescription_service: PrescriptionService, private program_service: ProgramService, private user_service: UserService, private route: ActivatedRoute, private api_service: ApiService, private snackBar: MatSnackBar) {
    // Getting user id
    this.user_id = this.user_service.get_user_id();

    // Getting user role
    this.user_role = this.user_service.get_user_role();
    if (this.user_role === 4) {
      this.isPhysio = true;
    } else if (this.user_role === 6) {
      this.isMa = true;
    } else {
      this.isPhysio = false;
    }

    this.route.params.subscribe(res => {
      this.patient_id = res.id;
    });

    this.is_dashboard = this.user_service.is_dashboard_access();

  }

  ngOnInit() {
    this.pickNgClass();
    this.isComplete();
  }

  ngAfterViewInit() {
    this.ds.forEach((dragel) => {
      dragel.scrollbarHidden = true;
    });
  }

  moveLeft(event) {
    const currScroll = this.indexInClass(event.target.offsetParent);
    const directiveArr = this.ds.toArray();
    directiveArr[0].moveLeft();
  }

  moveRight(event) {
    const currScroll = this.indexInClass(event.target.offsetParent);
    const directiveArr = this.ds.toArray();
    directiveArr[0].moveRight();

  }

  indexInClass(node) {
    const collection = document.getElementsByClassName(node.className);
    for (let i = 0; i < collection.length; i++) {
      if (collection[i] === node) {
        return i;
      }
    }
    return -1;
  }

  track_by(index, program) {

  }

  // Picks if the ngClass will be even or odd for styling
  pickNgClass() {
    if (this.index === 1) {
      this.pick_ng_class = 'odd';
    } else if (this.index === 0) {
      this.pick_ng_class = 'even';
    }

    if (this.user_program.status === 1) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
      this.pick_ng_class = 'program-disabled';
    }
  }

  // Deactivates/Activates user program for patient
  checkToggle(event) {
    if (event.checked === true) {
      this.program_service.activate_user_program(this.user_program.id)
        .subscribe(res => {
          this.snackBar.open('Program has been Enabled for User!', 'Close', {
            duration: 3000
          });
          if (this.index === 1) {
            this.pick_ng_class = 'odd';
          } else if (this.index === 0) {
            this.pick_ng_class = 'even';
          }
        });
    } else {
      this.program_service.deactivate_user_program(this.user_program.id)
        .subscribe(res => {
          this.snackBar.open('Program has been Disabled!', 'Close', {
            duration: 3000
          });
          if (this.index === 1) {
            this.pick_ng_class = 'odd program-disabled';
          } else if (this.index === 0) {
            this.pick_ng_class = 'even program-disabled';
          }
        });
    }
  }

  // Checks if patient has completed their program for today
  isComplete() {
    const complete = Object.entries(this.user_program.complete);
    const today_date = moment(new Date()).format('YYYY-MM-DD');
    const today_complete = complete.filter(x => x[0] === today_date)[0];

    if (today_complete) {
      const today_compliance = today_complete[1] as number;

      if (today_compliance === 100) {
        this.progress = 2;
      } else if (today_compliance > 0) {
        this.progress = 1;
      } else {
        this.progress = 0;
      }
    }
  }

  get_program_pdf() {
    this.api_service.get_pdf(`${environment.endpoints.exercise.programs}${this.user_program.id}/pdf`, this.user_program);
  }

  // Displays pdf for physio
  get_leaflet_pdf_physio(leaflet) {
    const pdf_id = leaflet.pdf_url.split('/');
    this.api_service.get_pdf('/leafletpdf/' + pdf_id[4], leaflet);
  }

  // Displays pdf for user
  get_leaflet_pdf_user(leaflet) {
    const pdf_id = leaflet.Leaflet.pdf_url.split('/');
    this.api_service.get_pdf(`/leafletpdf/${pdf_id[4]}?hash=${leaflet.hash}&program_leaflet_id=${leaflet.id}`, leaflet.Leaflet);
  }

  async edit_program(user_program_id, patient_id) {

    this.prescription_service.edit_program(user_program_id, patient_id);


    // await this.program_service.get_program(user_program_id).subscribe(res => {
    //   console.log(res);
    //   this.prescription_service.set_program_subject(res.program);
    // });

    // this.router.navigate([`/exercises/edit/programme/${user_program_id}/${patient_id}`]);

  }

}
