import {
  Injectable
} from '@angular/core';
import {
  environment
} from '@env/environment';
import {
  ApiService
} from '@app/shared/services/api.service';

/**
 *
 *
 * @export
 * @class OrganisationService
 */

@Injectable()
export class OrganisationService {


/**
 * TODO: Fix Doc Creates an instance of OrganisationService.
 * @param {ApiService} api_service
 * @memberof OrganisationService
 */
constructor(private api_service: ApiService) {}

  /**
   *
   *
   * @private
   * @param {String} name
   * @param {String} dob_format
   * @param {Number} patient_timeout
   * @param {Number} physio_timeout
   * @param {Number} parent_id
   * @memberof OrganisationService
   */

  private create_new_org(name: String, dob_format: String, patient_timeout: Number, physio_timeout: Number, parent_id: Number) {

    const body = {
      name: name,
      dob_format: dob_format,
      patient_timeout: patient_timeout,
      physio_timeout: physio_timeout,
      parentId: parent_id
    };

    this.api_service.post(`${environment.endpoints.org.orgs}`, body)
      .subscribe((res) => {
      });
  }

  /**
   *
   *
   * @private
   * @param {Number} org_id
   * @memberof OrganisationService
   */

  public get_org(org_id: Number) {

    return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}`);

  }

  public get_org_admin(org_id: Number, user_id: Number) {

    return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.admins.admins}${user_id}`);

  }

  public get_org_admins(org_id: Number, params?: any) {

    if (!params) {
      return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.admins.admins}`);
    } else {
      return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.admins.admins}${params}`);
    }

  }

  public create_admin (org_id, details) {
    return this.api_service.post(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.admins.admins}`, details);
  }

  public create_sub_organisation(details) {
    return this.api_service.post(`${environment.endpoints.org.orgs}`, details);
  }

  public update_organisation(details, org_id) {
    // console.log(org_id, details);

    return this.api_service.put(`${environment.endpoints.org.orgs}${org_id}`, details);
  }

  /**
   *
   *
   * @private
   * @memberof OrganisationService
   */

  private get_many_orgs() {

    this.api_service.get(`${environment.endpoints.org.orgs}`)
      .subscribe((res) => {

      });

  }

  public get_org_physios(org_id: number, params?) {
    if (!params) {
      return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.physio.physios}`);
    } else {
      return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.physio.physios}${params}`);
    }
  }

}
