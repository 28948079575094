import {
  Injectable
} from '@angular/core';
import {
  environment
} from '@env/environment';
import {
  ApiService
} from '@app/shared/services/api.service';
import {
  Observable
} from 'rxjs';
import { Program } from '@app/shared/models';

/**
 *
 *
 * @export
 * @class ProgramService
 */

@Injectable()
export class ProgramService {

  /**
   *Creates an instance of ProgramService.
   * @param {ApiService} api_service
   * @memberof ProgramService
   */

  constructor(private api_service: ApiService) {}

  /**
   * TODO:
   *
   * @private
   * @param {String} name
   * @param {Number} physio_id
   * @param {Number} user_id
   * @param {Array<Object>} program_exercises
   * @memberof ProgramService
   */

  public create_program(name: String, physio_id: Number, user_id: Number, program_exercises: Array < Object > ) {
    const body = {
      name: name,
      physio_id: physio_id,
      user_id: user_id,
      program_exercises: program_exercises
    };

    this.api_service.post(`${environment.endpoints.exercise.programs}`, body)
      .subscribe((res) => {

      });
  }

  /**
   *
   *
   * @private
   * @param {Number} program_id
   * @memberof ProgramService
   */

  public get_program(program_id: string): Observable<any> {

    return this.api_service.get(`${environment.endpoints.exercise.programs}${program_id}`);
  }

  public update_program(program, program_id): Observable<any> {

    return this.api_service.put(`${environment.endpoints.exercise.programs}${program_id}`, program);
  }

  /**
   *
   *
   * @private
   * @memberof ProgramService
   */

  private get_many_programs() {

    this.api_service.get(`${environment.endpoints.exercise.programs}`)
      .subscribe((res) => {
      });

  }

  /**
   * Returns a list of programs assigned to a specific user
   *
   * @private
   * @param {Number} user_id
   * @memberof ProgramService
   */

  public get_users_programs(user_id: Number) {

    // tslint:disable-next-line:max-line-length
    return this.api_service.get(`${environment.endpoints.user.users}${user_id}${environment.endpoints.exercise.programs}?order_by=updatedAt`);

  }

  /**
   * Deactivates a program for user by changing status
   *
   * @public
   * @param {Number} program_id
   * @memberof ProgramService
   */

   public deactivate_user_program(program_id: Number) {
     // tslint:disable-next-line:max-line-length
     return this.api_service.put(`${environment.endpoints.exercise.programs}${program_id}${environment.endpoints.exercise.deactivate}`, undefined);
   }

  /**
  * Activates a program for user by changing status
  *
  * @public
  * @param {Number} program_id
  * @memberof ProgramService
  */

  public activate_user_program(program_id: Number) {
    // tslint:disable-next-line:max-line-length
    return this.api_service.put(`${environment.endpoints.exercise.programs}${program_id}${environment.endpoints.exercise.activate}`, undefined);
  }

}
