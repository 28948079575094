import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/shared/services';

@Component({
  selector: 'salaso-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  // date data
  end_date: Date = new Date();
  start_date: Date = new Date();

  // object data
  id: Number;

  constructor(private user_service: UserService) {
    this.id = this.user_service.get_user_id();
  }

  // Initially det start_date 7 days from todays date
  ngOnInit() {
    this.start_date.setDate(this.end_date.getDate());
  }

  // Gets date from date picker
  change_date(event: any) {
    this.start_date = event;
  }

}
