import {
  Injectable
} from '@angular/core';
import {
  BehaviorSubject
} from 'rxjs';
import { Exercise, Program, ProgramExercise } from '@app/shared/models';
import { AssignExercise, AssignProgram, AssignLeaflet } from '@app/shared/models/assign_program.model';
import { environment } from '@env/environment';
import { ApiService } from '@app/shared/services';
import { UserLeaflet } from '@app/shared/models/leaflet.model';
import { Router } from '@angular/router';
import { ProgramService } from '@app/shared/services/program.service';

@Injectable()
export class PrescriptionService {
  constructor(
    private api_service: ApiService,
    private router: Router,
    private program_service: ProgramService
  ) {
    this.current_program.subscribe(res => {
      console.log(`Current Program Updated`, res);
    });

    this.current_exercises.subscribe(res => {
      console.log(`Current Exercises Updated`, res);
    });

    this.current_leaflets.subscribe(res => {
      console.log(`Current Leaflets Updated`, res);
    });
  }

  private program_to_create = new BehaviorSubject({
    name: '',
    Program_Exercises: [],
    Program_Leaflets: []
  });
  current_program = this.program_to_create.asObservable();

  private exercises_in_program = new BehaviorSubject([] as Exercise[]);
  current_exercises = this.exercises_in_program.asObservable();

  private assigned_program_rename = new BehaviorSubject('' as String);
  current_rename = this.assigned_program_rename.asObservable();

  private leaflets_in_program = new BehaviorSubject([] as UserLeaflet[]);
  current_leaflets = this.leaflets_in_program.asObservable();

  private was_checked = new BehaviorSubject(false);
  update_now = this.was_checked.asObservable();

  set_reassigned_name(string_to_reassign: string) {
    this.assigned_program_rename.next(string_to_reassign);
  }

  set_program_subject(program) {
    this.program_to_create.next(program);
  }

  update_sidebar(boolean) {
    this.was_checked.next(boolean);
  }

  set_program_name(name: string) {
    let program;
    this.current_program.subscribe((res: AssignProgram) => {
      program = res;
      program.name = name;
    });
    this.set_program_subject(program);
  }

  add_program_exercise(program_exercise: Exercise) {
    const exercise_to_add = {
      exercise_id: program_exercise.id
    };

    let program;
    let exercises: Exercise[];

    this.program_to_create.subscribe((res: AssignProgram) => {
      program = res;
    });

    if (program) {
      program.Program_Exercises.push(exercise_to_add);
      this.set_program_subject(program);

      this.current_exercises.subscribe(current_exercises => {
        exercises = current_exercises;
      });

      exercises.push(program_exercise);

      this.exercises_in_program.next(exercises);
    }
  }

  remove_program_exercise(program_exercise: Exercise) {
    let program: AssignProgram;
    let exercises: Exercise[];

    this.program_to_create.subscribe((current_res: AssignProgram) => {
      program = current_res;
    });

    this.exercises_in_program.subscribe((current_exercises: Exercise[]) => {
      exercises = current_exercises;
    });

    let i = 0;
    program.Program_Exercises.forEach(program_exercises => {
      if (program_exercises.exercise_id === program_exercise.id) {
        program.Program_Exercises.splice(i, 1);
      }
      i++;
    });

    let j = 0;
    exercises.forEach(ex => {
      if (ex.id === program_exercise.id) {
        exercises.splice(j, 1);
      }
      j++;
    });

    this.set_program_subject(program);
    this.exercises_in_program.next(exercises);

    // let program: AssignProgram;
    // let exercises: Exercise[];

    // this.program_to_create.subscribe((current_res: AssignProgram) => {
    //   program = current_res;
    // });

    // this.exercises_in_program.subscribe((current_exercises: Exercise[]) => {
    //   exercises = current_exercises;
    // });

    // let i = 0;
    // program.Program_Exercises.forEach(program_exercises => {
    //   if (program_exercises.exercise_id === program_exercise.id) {
    //     program.Program_Exercises.splice(i, 7);
    //   }
    //   i++;
    // });

    // let j = 0;
    // exercises.forEach(ex => {
    //   if (ex.id === program_exercise.id) {
    //     exercises.splice(j, 1);
    //   }
    //   j++;
    // });

    // this.set_program_subject(program);
    // this.exercises_in_program.next(exercises);
  }

  add_program_leaflet(program_leaflet: UserLeaflet) {
    let program;
    let leaflets: UserLeaflet[];

    this.program_to_create.subscribe((res: AssignProgram) => {
      program = res;
    });

    if (program) {
      program.Program_Leaflets.push(program_leaflet);
      this.set_program_subject(program);

      this.current_leaflets.subscribe(current_leaflets => {
        leaflets = current_leaflets;
      });

      leaflets.push(program_leaflet);

      this.leaflets_in_program.next(leaflets);
    }
  }

  remove_program_leaflet(program_leaflet: UserLeaflet) {
    let program: AssignProgram;
    let leaflets: UserLeaflet[];

    this.program_to_create.subscribe((current_res: AssignProgram) => {
      program = current_res;
    });

    this.leaflets_in_program.subscribe((current_leaflets: UserLeaflet[]) => {
      leaflets = current_leaflets;
    });

    let i = 0;
    program.Program_Leaflets.forEach(program_leaflets => {
      if (program_leaflet.id === program_leaflets.id) {
        program.Program_Leaflets.splice(i, 1);
      }
      i++;
    });

    let j = 0;
    leaflets.forEach(leaflet => {
      if (leaflet.id === program_leaflet.id) {
        leaflets.splice(j, 1);
      }
      j++;
    });

    this.set_program_subject(program);
    this.leaflets_in_program.next(leaflets);
  }

  clear_all() {
    let program: AssignProgram;
    this.program_to_create.subscribe((res: AssignProgram) => {
      program = res;
    });

    this.program_to_create.next({
      name: '',
      Program_Exercises: [],
      Program_Leaflets: []
    });
    this.exercises_in_program.next([]);
    this.leaflets_in_program.next([]);
  }

  public create_program(program: AssignProgram) {
    return this.api_service.post(
      `${environment.endpoints.exercise.programs}`,
      program
    );
  }

  async edit_program(user_program_id, patient_id) {
    // await this.program_service.get_program(user_program_id).subscribe(res => {
    //   console.log(res);

    //   this.set_program_subject(res.program);
    // });
    this.router.navigate([
      `/exercises/edit/programme/${user_program_id}/${patient_id}`
    ]);
  }

  return_program() {
    let program;
    this.current_program.subscribe(res => {
      program = res;
    });

    return program;
  }

  get_user_physio_draft(user_id) {
    return this.api_service.get(
      `/program_draft/users/${user_id}/latest_user_draft`
    );
  }

  send_draft_program(user_id, program_id) {
    const body = {};

    return this.api_service.post(
      `/program_draft/users/${user_id}/program_drafts/${program_id}/send`,
      body
    );
  }

  update_draft_program(user_id, program_id, program) {
    return this.api_service.put(
      `/program_draft/users/${user_id}/program_drafts/${program_id}`,
      program
    );
  }

  create_draft_of_program(user_id, program_id) {
    return this.api_service.get(
      `/program_draft/users/${user_id}/programs/${program_id}`
    );
  }

  create_draft_program_from_template(user_id, template_id) {
    return this.api_service.get(
      `/program_draft/users/${user_id}/templates/${template_id}`
    );
  }
}

