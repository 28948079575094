import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService, ApiService } from '@app/shared/services';
import { Router, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';


@Component({
  selector: 'salaso-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'Salaso';
  patient_goal: String;
  activity_id: number;
  is_edit_goal = false;

  // tslint:disable-next-line:max-line-length
  constructor(private user_service: UserService, private router: Router, private titleService: Title, private route: ActivatedRoute, private http: HttpClient) {
    // this.user_service.populate_on_startup();
    if(window.location.href.indexOf("?token=") > -1){
      window.localStorage.removeItem('access_token');
      window.localStorage.removeItem('refresh_token');
    }

    // this.route.queryParams.subscribe(res => {
    //   console.log(res);

    //   if (res.token) {

    //   } else {

    //   }
    // });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(res => {
      console.log(res);

      console.log(this.user_service.get_user_role());

      if (res.token) {

        this.user_service.purge_all_auth();

        const body = {};


        const httpOptions = {
          headers: new HttpHeaders({
            'x-access-token': `${res.token}`
          })
        };

        // tslint:disable-next-line:max-line-length
        this.http.post(`${environment.api_url}${environment.endpoints.auth.token_exchange}`, body, httpOptions).toPromise().then((new_tokens: any) => {
          console.log(new_tokens);

          this.user_service.purge_all_auth();
          this.user_service.save_token(`access_token`, new_tokens.access_token);
          this.user_service.save_token(`refresh_token`, new_tokens.refresh_token);
          this.user_service.populate_on_startup2();

          if (this.user_service.get_user_role() === 4) {
            console.log(this.user_service.get_patient_context_id());
            console.log(this.user_service.get_touchworks_speciality());
            console.log(this.user_service.get_touchworks_default_template_org());
            console.log(this.user_service.is_touchworks_user());
            if(this.user_service.is_dashboard_access()){
              this.router.navigate([`/users`]);
            }else{
              this.router.navigate([`/users/user/${this.user_service.get_patient_context_id()}`]);  
            }
          }
          if (this.user_service.get_user_role() === 6) {
            console.log("moa",this.user_service.get_patient_context_id());
            console.log("moa",this.user_service.get_touchworks_speciality());
            console.log("moa",this.user_service.get_touchworks_default_template_org());
            console.log("moa",this.user_service.is_touchworks_user());

            this.router.navigate([`/users/user/${this.user_service.get_patient_context_id()}`]);
          }
        });

      } else {
        console.log(`Here for some reason`);

        this.user_service.populate_on_startup();
      }
    });

    // console.log(window.location.origin);

    switch (window.location.origin) {
      case 'http://localhost:4200':
        this.titleService.setTitle('Salaso Development Environment');
        break;
      case 'https://v2devel.salaso.com':
        this.titleService.setTitle('Salaso Sandbox Environment');
        break;
      case 'https://v2.salaso.com':
        this.titleService.setTitle('Salaso Health Solutions');
        break;
      case 'https://mi3d.salaso.com':
        this.titleService.setTitle('mi3d by 3d Rehabilitation');
        break;
      case 'https://mi3d.uk':
        this.titleService.setTitle('mi3d by 3d Rehabilitation');
        break;
      default:
        this.titleService.setTitle('Salaso Health Solutions');
        break;
    }



  }

  ngOnDestroy(): void {
    if (this.user_service.is_touchworks_user()) {
      this.user_service.destroy_tokens();
    }
  }


}
// new branch