import {
  BrowserModule, Title
} from '@angular/platform-browser';
import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import {
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core';

import {
  AppRoutingModule
} from './app-routing.module';
import {
  AppComponent
} from './app.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SharedModule } from '@shared/shared.module';
import { HomeComponent } from './home/home.component';
import { ExerciseModule} from './modules/exercises/exercise.module';
import { StaticModule } from './modules/static/static.module';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  NotFoundComponent
} from './not-found/not-found.component';
import {
  HttpClientModule
} from '@angular/common/http';
import {
  ApiService,
  LoggingService,
  OrganisationService,
  PhysioService,
  ProgramService,
  QuestionnaireService,
  TemplateService,
  ExerciseService,
  LeafletService
} from '@shared/services';

import { UserService } from './shared/services/user.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { OverlayModule } from '@angular/cdk/overlay';
import { OverlayContainer } from '@angular/cdk/overlay';
import { RouterGaurdService } from '@app/shared/gaurds/router-gaurd.service';
import { CompleteSurveyComponent } from './modules/survey/components/complete-survey/complete-survey.component';



@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    SharedModule,
    HttpClientModule,
    ExerciseModule,
    StaticModule,
   // FontAwesomeModule,
    // ServiceWorkerModule.register('/ngsw-worker.js', {
    //   enabled: environment.production
    // }),
    // environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : [],
    OverlayModule
  ],
  exports: [],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    NotFoundComponent,
    CompleteSurveyComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    Title,
    UserService,
    SharedModule,
    ApiService,
    LoggingService,
    OrganisationService,
    PhysioService,
    ProgramService,
    QuestionnaireService,
    TemplateService,
    ExerciseService,
    LeafletService,
    RouterGaurdService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement()
      .classList.add('allscripts-theme');
  }
}
