import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ExerciseDetailsComponent} from '@app/modules/exercises/pages/exercise-details/exercise-details.component';
import { ProgramListComponent } from '@app/modules/exercises/components/program-list/program-list.component';
import { StatsComponent } from '@app/modules/exercises/pages/stats/stats.component';
import { ActivityComponent } from '@app/modules/exercises/pages/activity/activity.component';
import { RouterGaurdService } from '@app/shared/gaurds/router-gaurd.service';
import { PatientSurveysPageComponent } from './pages/patient-surveys-page/patient-surveys-page.component';


const routes: Routes = [
  {
    path: '',
    component: ProgramListComponent,
    canActivate: [RouterGaurdService],
    data: {
      permission: {
        only: [5],
        redirectTo: 'exercises'
      }
    }

  },
  {
    path: ':program_id/exercise/:exercise_id',
    component: ExerciseDetailsComponent,
    canActivate: [RouterGaurdService],
    data: {
      permission: {
        only: [5],
        redirectTo: 'exercises'
      }
    }
  },
  {
    path: 'stats',
    component: StatsComponent,
    canActivate: [RouterGaurdService],
    data: {
      permission: {
        only: [5],
        redirectTo: 'exercises'
      }
    }
    // canActivate: [PatientGaurdService]
  },
  {
    path: 'surveys',
    component: PatientSurveysPageComponent,
    canActivate: [RouterGaurdService],
    data: {
      permission: {
        only: [5],
        toggles: 'surveys',
        redirectTo: 'exercises',
      },
    },
  },
  {
    path: 'activity',
    component: ActivityComponent,
    canActivate: [RouterGaurdService],
    data: {
      permission: {
        only: [5],
        redirectTo: 'exercises'
      }
    }
    // canActivate: [PatientGaurdService]
  }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)]
})
export class ExerciseRoutingModule { }
