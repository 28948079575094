///<reference path="../node_modules/@types/node/index.d.ts" />

import {
  enableProdMode
} from '@angular/core';
import {
  platformBrowserDynamic
} from '@angular/platform-browser-dynamic';
import {
  AppModule
} from './app/app.module';
import {
  environment
} from './environments/environment';
import {
  hmrBootstrap
} from './hmr';
import { UserService } from '@app/shared/services';
if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
  }
} else {
  bootstrap();
}
