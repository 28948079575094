import { Component, OnInit, Input } from '@angular/core';
import { LoggingService } from '@app/shared/services';
import { UserEffort } from '@app/shared/models/user_effort.model';
import * as moment from 'moment';

@Component({
    selector: 'salaso-effort-chart',
    templateUrl: './effort-chart.component.html',
    styleUrls: ['./effort-chart.component.scss']
})
export class EffortChartComponent implements OnInit {
    // holding data from services
    user_effort: UserEffort;
    @Input() user_id: Number;

    // dates
    start_date: Date = new Date();
    end_date: Date = new Date();

    // chart data
    chartOptions: any;
    chartLabels: any = [];
    chartType: String;
    chartData: any = [];

    // Checking if chart is ready
    isChartReady = false;

    // Gets date from datepicker and refresh user_effort data and chart
    @Input()
    set update_date(date: Date) {
        this.start_date = new Date(date);
        this.end_date.setDate(this.start_date.getDate() + 6);
        this.end_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() + 6);
        this.chartData.length = 0;
        this.get_logging_service();
    }

    constructor(private logging_service: LoggingService) {
        // Getting the start date, start date is today's date minus 7 days
        // this.start_date.setFullYear(this.end_date.getFullYear(), this.end_date.getMonth(), this.end_date.getDate() - 7);
    }

    ngOnInit() {

    }

    // Getting user effort data from date range
    get_logging_service() {
        /**
         * @param {Number} user_id
         * @param {String} start_date
         * @param {String} end_date
         * @memberof EffortChartComponent
         */
        this.logging_service.get_user_effort(this.user_id, this.start_date.toISOString(), this.end_date.toISOString())
            .subscribe((res: UserEffort) => {
                this.user_effort = res;
                this.initChart();
            });
    }

    // Initializing chart
    public initChart() {

        // Chart Type
        this.chartType = 'line';

        // Chart Labels
        this.chartLabels = this.getDates(this.user_effort.date_range.from, this.user_effort.date_range.to);

        // Chart Data
        // Gets each program and pushes to chartData, then gets each effort inside each program and pushes to data
        if (this.user_effort.programs.length > 0) {
            this.user_effort.programs.forEach((program, i) => {
                this.chartData.push({
                    label: [program.name || 'Program ' + (i + 1)],
                    data: [],
                    fill: false,
                    pointRadius: 10,
                    pointHoverRadius: 15,
                    showLine: false,
                    responsive: true
                });
                program.User_Efforts.forEach((effort) => {
                    this.chartData[i].data.push({
                        x: moment(effort.createdAt).format('DD/MM'),
                        y: effort.effort_val
                    });
                });
            });
        } else {
            this.chartData = [{
                label: '',
                data: []
            }];
        }

        // Chart Options
        this.chartOptions = {
            responsive: true,
            // Tooltip when hovering over point
            tooltips: {
                callbacks: {
                    title: function (t, d) {
                        return d.datasets[t[0].datasetIndex].label;
                    },
                    label: function (t, d) {
                        return d.datasets[t.datasetIndex].label + ': (Date: ' + d.datasets[t.datasetIndex].data[t.index].x +
                            ', Effort:  ' + t.yLabel + ')';
                    }
                }
            },
            // X axis and Y axis scales, put Y axes to 0-10
            scales: {
                yAxes: [{
                    ticks: {
                        suggestedMin: 0,
                        suggestedMax: 10,
                        fontColor: '#aaa'
                        // stepSize: 2
                    }
                }],
                xAxes: [{
                  ticks: {
                    fontColor: '#aaa'
                  }
                }]
            },
            // Legend positioned at bottom, lets you toggle each program on or off
            legend: {
                display: true,
                position: 'bottom'
            },
            // Point shape is circle
            elements: {
                point: {
                    pointStyle: 'circle'
                }
            },
            // Individual hovering of each point
            hover: {
                mode: 'x'
            }
        };

        // Chart is now ready to be displayed
        this.isChartReady = true;
    }

    // Event when chart is clicked
    public chartClicked(e: any): void {

    }

    // Event when chart is hovered
    public chartHovered(e: any): void {

    }

    // Gets array of two selected dates
    // Used to get the values of X axis scale
    getDates(startDate, stopDate) {
        const dateArray = [];
        let currentDate = moment(startDate);
        dateArray.push(moment(currentDate).format('DD/MM'));
        stopDate = moment(stopDate);
        while (currentDate <= stopDate) {
            currentDate = moment(currentDate).add(1, 'days');
            dateArray.push(moment(currentDate).format('DD/MM'));
        }
        return dateArray;
    }

    // Refreshes user_effort data and chart with previous day
    prevDay() {
        this.start_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() - 1);
        this.end_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() + 6);
        this.chartData.length = 0;
        this.get_logging_service();
    }

    // Refreshes user_effort data and chart with next day
    nextDay() {
        this.start_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() + 1);
        this.end_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() + 6);
        this.chartData.length = 0;
        this.get_logging_service();
    }
}
