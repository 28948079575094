import {
    Injectable
} from '@angular/core';
import {
    environment
} from '@env/environment';
import {
    HttpClient,
    HttpParams,
    HttpRequest
} from '@angular/common/http';
import {
    Observable
} from 'rxjs';

import {
    catchError
} from 'rxjs/operators';
import {
    throwError
} from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { saveAs } from 'file-saver/src/FileSaver';
/**
 *
 *
 * @export
 * @class ApiService
 */

@Injectable()
export class ApiService {

    /**
     *Creates an instance of ApiService.
     * @param {HttpClient} http
     * @memberof ApiService
     */

    constructor(private http: HttpClient, public snackBar: MatSnackBar) { }

    cachedRequests: Array<HttpRequest<any>> = [];

    /**
     *
     *
     * @param {string} path
     * @param {HttpParams} [params=new HttpParams()]
     * @returns {Observable<any>}
     * @memberof ApiService
     */

    public get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        return this.http.get(`${environment.api_url}${path}`, {
            params
        })
            .pipe(catchError(this.formatErrors));
    }

    getPdf(path: string) {
        return this.http.get(`${environment.api_url}${path}`, { responseType: 'blob' }).pipe(
            catchError(this.formatErrors)
        )
    }

    /**
     *
     *
     * @param {string} path
     * @returns {Observable<any>}
     * @memberof ApiService
     */

    public get_pdf(path: string, pdfObject?: any): any {
        return this.http.get(`${environment.api_url}${path}`, { responseType: 'blob' }).pipe(
            catchError(this.formatErrors)
        ).subscribe(
            (response) => this.onGetPdfSuccess(response, pdfObject),
            () => this.onGetPdfError()
        );
    }

    onGetPdfSuccess(response, pdfObject: any) {
        const file = new Blob([response], { type: 'application/pdf' });

        if (file.size > 0) {
            this.snackBar.open('Downloading PDF', 'Close', {
                duration: 3000
            });

            saveAs(response, pdfObject.title || pdfObject.name + '.pdf');
        } else {
            this.snackBar.open('Error downloading PDF', 'Close', {
                duration: 3000
            });
        }
    }

    onGetPdfError() {
        this.snackBar.open('Error downloading PDF', 'Close', {
            duration: 3000
        });
    }

    async showFile(blob, pdf_object?: any) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([blob], { type: 'application/pdf' });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            await window.navigator.msSaveOrOpenBlob(newBlob, pdf_object.title || pdf_object.name);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = await window.URL.createObjectURL(newBlob);
        const link = await document.createElement('a');
        document.body.appendChild(link);
        link.href = await data;
        link.target = '_blank';
        // For actual download of the file
        link.download = pdf_object.title || pdf_object.name;
        link.click();
        // Opening pdf
        window.open(data, '_blank');

    }


    /**
     *
     *
     * @param {string} path
     * @param {Object} body
     * @returns {Observable<any>}
     * @memberof ApiService
     */

    public post(path: string, body: Object): Observable<any> {
        return this.http.post(`${environment.api_url}${path}`, body)
            .pipe(catchError(this.formatErrors));
    }

    /**
     *
     *
     * @param {string} path
     * @param {Object} body
     * @returns {Observable<any>}
     * @memberof ApiService
     */

    public patch(path: string, body: Object): Observable<any> {
        return this.http.patch(`${environment.api_url}${path}`, JSON.stringify(body))
            .pipe(catchError(this.formatErrors));
    }

    /**
     *
     *
     * @param {string} path
     * @param {Object} body
     * @returns {Observable<any>}
     * @memberof ApiService
     */

    public put(path: string, body: Object): Observable<any> {
        return this.http.put(`${environment.api_url}${path}`, JSON.stringify(body),
            { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(this.formatErrors));
    }

    /**
     *
     *
     * @param {*} path
     * @returns {Observable<any>}
     * @memberof ApiService
     */

    public delete(path, body: Object): Observable<any> {
        return this.http.delete(`${environment.api_url}${path}`, body).pipe(catchError(this.formatErrors));
    }

    /**
     *
     *
     * @private
     * @param {*} error
     * @returns
     * @memberof ApiService
     */

    private formatErrors(error: any) {
        return throwError(error);
    }

    /**
     *
     *
     * @param {*} request
     * @memberof ApiService
     */

    public collectFailedRequest(request): void {
        this.cachedRequests.push(request);
    }

    /**
     *
     *
     * @returns {HttpRequest<any>[]}
     * @memberof ApiService
     */

    public retry_failed_requests(): HttpRequest<any>[] {
        const retry: HttpRequest<any>[] = [];
        this.cachedRequests.forEach(request => {
            request = request.clone({
                setHeaders: {
                    'x-access-token': localStorage.getItem('access_token')
                }
            });

            retry.push(request);
        });

        return retry;
    }

}
