import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiService } from '@app/shared/services/api.service';
import { BehaviorSubject } from 'rxjs';

/**
 * @export
 * @class ExerciseService
 */

@Injectable()
export class ExerciseService {
    constructor(private api_service: ApiService) {}

    private exercise_filters = new BehaviorSubject({});
    current_filters = this.exercise_filters.asObservable();

    /**
     *
     * @public
     * @memberof ExerciseService
     */
    public get_many_exercises() {
        return this.api_service.get(`${environment.endpoints.exercise.exercises}`);
    }

    /**
     *
     * @public
     * @param exercise_id
     * @memberof ExerciseService
     */
    public get_exercise(exercise_id: String) {
        return this.api_service.get(`${environment.endpoints.exercise.exercises}${exercise_id}`);
    }

    /**
     * @public
     * @param filter Filters to be added
     * @param filter_value Gets the ids of selected values so they can be reassigned to dropdowns
     * @memberof ExerciseService
     */
    public set_exercise_filter(filter: any, filter_value?: any) {
        this.exercise_filters.next({filter, filter_value});
    }
}
