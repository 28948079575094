import {
  Injectable
} from '@angular/core';
import {
  environment
} from '@env/environment';
import {
  ApiService
} from '@app/shared/services/api.service';

/**
 *
 *
 * @export
 * @class LoggingService
 */

@Injectable()
export class LoggingService {

  /**
   *Creates an instance of LoggingService.
   * @param {ApiService} api_service
   * @memberof LoggingService
   */

  constructor(private api_service: ApiService) {}

  /**
   *
   *
   * @private
   * @param {Number} program_exercise_id
   * @param {Number} reps
   * @param {Number} sets
   * @memberof LoggingService
   */

  public create_activity_record(program_exercise_id: Number, reps?: Number, sets?: Number, hr_max?: Number) {

    const body = {
      reps: reps,
      sets: sets,
      hr_max: hr_max
    };

    // tslint:disable-next-line:max-line-length
    return this.api_service.post(`${environment.endpoints.logging.program_exercises}${program_exercise_id}${environment.endpoints.logging.activity_record}`, body);

  }


  /**
   *
   *
   * @private
   * @param {Number} program_id
   * @param {Number} effort_val
   * @memberof LoggingService
   */

  public create_program_effort(program_id: Number, effort_val: Number) {

    const body = {
      effort_val: effort_val
    };
    // tslint:disable-next-line:max-line-length
    return this.api_service.post(`${environment.endpoints.logging.programs}${program_id}${environment.endpoints.logging.effort}`, body);

  }

  /**
   *
   *
   * @private
   * @param {Number} user_id
   * @param {Number} pain_val
   * @memberof LoggingService
   */
  public create_user_pain(user_id: Number, pain_val: Number) {
    const body = {
      pain_val: pain_val
    };
    // tslint:disable-next-line:max-line-length
    return this.api_service.post(`${environment.endpoints.user.users}${user_id}${environment.endpoints.logging.pain}`, body);

  }

  /**
   *
   *
   * @public
   * @param {Number} user_id
   * @param {String} title
   * @memberof LoggingService
   */

  public create_user_activity(user_id: Number, title: String) {

    const body = {
      title: title
    };
    // tslint:disable-next-line:max-line-length
    return this.api_service.post(`${environment.endpoints.user.users}${user_id}${environment.endpoints.logging.activity}`, body);

  }

  /**
   *
   *
   * @public
   * @param {Number} activity_id
   * @param {Number} score
   * @memberof LoggingService
   */

  public create_activity_score(activity_id: Number, score: Number) {

    const body = {
      score: score
    };
    // tslint:disable-next-line:max-line-length
    return this.api_service.post(`${environment.endpoints.logging.activities}${activity_id}${environment.endpoints.logging.score}`, body);

  }

  /**
   *
   *
   * @public
   * @param {Number} user_id
   * @param {String} start_date
   * @param {String} end_date
   * @memberof LoggingService
   */

  public get_user_effort(user_id: Number, start_date: String, end_date: String) {
    // tslint:disable-next-line:max-line-length
    return this.api_service.get(`${environment.endpoints.user.users}${user_id}${environment.endpoints.logging.effort}?from=${start_date}&to=${end_date}`);

  }

  /**
   *
   *
   * @public
   * @param {Number} user_id
   * @param {String} start_date
   * @param {String} end_date
   * @memberof LoggingService
   */

  public get_user_pains(user_id: Number, start_date: String, end_date: String) {
    // tslint:disable-next-line:max-line-length
    return this.api_service.get(`${environment.endpoints.user.users}${user_id}${environment.endpoints.logging.pain}?from=${start_date}&to=${end_date}`);

  }

  /**
   *
   *
   * @public
   * @param {Number} user_id
   * @param {String} start_date
   * @param {String} end_date
   * @memberof LoggingService
   */

  public get_user_activity_scores(user_id: Number, start_date: String, end_date: String) {
    // tslint:disable-next-line:max-line-length
    return this.api_service.get(`${environment.endpoints.user.users}${user_id}${environment.endpoints.logging.activity_score}?from=${start_date}&to=${end_date}`);

  }

  /**
   *
   *
   * @public
   * @param {Number} user_id
   * @param {String} start_date
   * @param {String} end_date
   * @memberof LoggingService
   */
  public get_user_completion(user_id: Number, start_date: String, end_date: String) {
    // tslint:disable-next-line:max-line-length
    return this.api_service.get(`${environment.endpoints.user.users}${user_id}${environment.endpoints.logging.completion}?from=${start_date}&to=${end_date}`);
  }

  /**
   *
   *
   * @public
   * @param {Number} user_id
   * @param {String} start_date
   * @param {String} end_date
   * @memberof LoggingService
   */
  public get_user_compliance(user_id: Number, start_date: String, end_date: String) {
    // tslint:disable-next-line:max-line-length
    return this.api_service.get(`${environment.endpoints.user.users}${user_id}${environment.endpoints.logging.compliance}?from=${start_date}&to=${end_date}`);
  }

  /**
   *
   *
   * @public
   * @param {Number} user_id
   * @memberof LoggingService
   */
  public get_user_activity_goal(user_id: Number) {
    return this.api_service.get(`${environment.endpoints.user.users}${user_id}${environment.endpoints.logging.activity}`);
  }

  /**
   *
   *
   * @public
   * @param {Number} user_id
   * @param {Number} activity_id
   * @param {String} title
   * @memberof LoggingService
   */
   public update_user_activity_goal(user_id: Number, activity_id: Number, title: String) {
     const body = {
      title: title
     };
     // tslint:disable-next-line:max-line-length
     return this.api_service.put(`${environment.endpoints.user.users}${user_id}${environment.endpoints.logging.activity}${activity_id}`, body);
   }

   /**
    *
    * @public
    * @param {Number} user_id
    * @memberof LoggingService
    */
  public get_patient_user_compliance(user_id: Number) {
    return this.api_service.get(`${environment.endpoints.user.users}${user_id}${environment.endpoints.logging.compliance}`);
  }

  /**
   *
   * @param user_id
   * @param program_id
   * @memberof LoggingService
   */
  public get_user_latest_compliance(user_id: Number, program_id: Number) {
    // tslint:disable-next-line:max-line-length
    return this.api_service.get(`${environment.endpoints.user.users}${user_id}${environment.endpoints.exercise.programs}${program_id}${environment.endpoints.logging.compliance}${environment.endpoints.logging.latest}`);
  }

}
