import {
  Injectable,
  Component,
  Inject,
  ViewChild,
  ViewChildren,
  QueryList
} from '@angular/core';
import {
  Observable,
  BehaviorSubject

} from 'rxjs';
import { throwError } from 'rxjs';
import {
  catchError
} from 'rxjs/operators';
import {
  Router, ActivatedRoute
} from '@angular/router';
import {
  Login
} from '@app/shared/models';
import {
  ApiService
} from './api.service';
import * as jwt_decode from 'jwt-decode';
import {
  environment
} from '@env/environment';
import {
  MatDialogRef,
  MatDialog,
  MatCheckbox,
  MatButton,
  MAT_DIALOG_DATA
} from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Http, Headers } from '@angular/http';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';

/**
 *
 *
 * @export
 * @class UserService
 */

@Injectable()
export class UserService {


  /**
   *Creates a single instance of UserService. Do not relocate as it will break all auth functionality in Salaso
   * @param {ApiService} api_service
   * @param {Router} router
   * @memberof UserService
   */
  constructor(public dialog: MatDialog, private api_service: ApiService, private router: Router) { }


  private current_user_subject = new BehaviorSubject<Login>({} as Login);
  current_user = this.current_user_subject.asObservable();
  private is_authenticated_subject = new BehaviorSubject<Boolean>(false);
  is_authenticated = this.is_authenticated_subject.asObservable();
  private current_user_role_subject = new BehaviorSubject<Number>(0 as Number);
  current_user_role = this.current_user_role_subject.asObservable();

  populate_on_startup() {
    if (this.get_token('access_token')) {
      console.log('role', this.get_user_role());
      // this.get_consent();
      switch (this.get_user_role()) {
        case 1: // Admin
          // this.router.navigate([``]);
          break;
        case 2: // Org Admin
          this.api_service.get(`${environment.endpoints.org.orgs}${this.get_user_org_id()}/admins/${this.get_user_id()}`)
            .subscribe(
              org_login => {
                this.current_user_role_subject.next(2);
                this.set_auth_subject(org_login.login);
                this.current_user_role_subject.next(2);
              },
              error => {
                this.purge_all_auth();

              }
            );
          break;
        case 4: // Physio
          this.current_user_role_subject.next(4);
          this.api_service.get(`${environment.endpoints.physio.physios}${this.get_user_id()}`)
            .subscribe(
              physio_login => {
                this.current_user_role_subject.next(4);
                this.set_auth_subject(physio_login.login);
                this.current_user_role_subject.next(4);
              },
              error => {
                this.purge_all_auth();
              }
            );
          break;
        case 5: // Patient/User
          this.api_service.get(`${environment.endpoints.user.users}${this.get_user_id()}`)
            .subscribe(
              user_login => {
                this.current_user_role_subject.next(5);
                this.set_auth_subject(user_login.login);
                this.current_user_role_subject.next(5);
              },
              error => {
                this.purge_all_auth();
              }
            );
          break;
        case 6: // Physio
          this.current_user_role_subject.next(6);
          this.api_service.get(`${environment.endpoints.physio.physios}${this.get_user_id()}`)
            .subscribe(
              physio_login => {
                this.current_user_role_subject.next(6);
                this.set_auth_subject(physio_login.login);
                this.current_user_role_subject.next(6);
              },
              error => {
                this.purge_all_auth();
              }
            );
          break;
        default:
          // this.router.navigate([`auth`]);
          break;
      }
    } else if (this.router.url.includes('auth/register')) {
      this.purge_all_auth();
    } else if (this.router.url === '/auth/forgotpassword') {
      this.purge_all_auth();
    } else {
      this.purge_all_auth();
    }
  }
  populate_on_startup2() {
    if (this.get_token('access_token')) {
      console.log('role', this.get_user_role());
      // this.get_consent();
      switch (this.get_user_role()) {
        case 1: // Admin
          // this.router.navigate([``]);
          break;
        case 2: // Org Admin
          this.api_service.get(`${environment.endpoints.org.orgs}${this.get_user_org_id()}/admins/${this.get_user_id()}`)
            .subscribe(
              org_login => {
                this.current_user_role_subject.next(2);
                this.set_auth_subject2(org_login.login);
                this.current_user_role_subject.next(2);
              },
              error => {
                this.purge_all_auth();

              }
            );
          break;
        case 4: // Physio
          this.current_user_role_subject.next(4);
          this.api_service.get(`${environment.endpoints.physio.physios}${this.get_user_id()}`)
            .subscribe(
              physio_login => {
                this.current_user_role_subject.next(4);
                this.set_auth_subject2(physio_login.login);
                this.current_user_role_subject.next(4);
              },
              error => {
                this.purge_all_auth();
              }
            );
          break;
        case 5: // Patient/User
          this.api_service.get(`${environment.endpoints.user.users}${this.get_user_id()}`)
            .subscribe(
              user_login => {
                this.current_user_role_subject.next(5);
                this.set_auth_subject(user_login.login);
                this.current_user_role_subject.next(5);
              },
              error => {
                this.purge_all_auth();
              }
            );
          break;
        case 6: // Physio
          this.current_user_role_subject.next(6);
          this.api_service.get(`${environment.endpoints.physio.physios}${this.get_user_id()}`)
            .subscribe(
              physio_login => {
                this.current_user_role_subject.next(6);
                this.set_auth_subject2(physio_login.login);
                this.current_user_role_subject.next(6);
              },
              error => {
                this.purge_all_auth();
              }
            );
          break;
        default:
          // this.router.navigate([`auth`]);
          break;
      }
    } else if (this.router.url.includes('auth/register')) {
      this.purge_all_auth();
    } else if (this.router.url === '/auth/forgotpassword') {
      this.purge_all_auth();
    } else {
      this.purge_all_auth();
    }
  }

  set_auth_subject(login: Login) {

    this.current_user_subject.next(login);
    this.is_authenticated_subject.next(true);
    // let msg = 'text';
    switch (this.get_user_role()) {
      case 1: // Admin
        this.current_user_subject.next(login);
        this.is_authenticated_subject.next(true);
        this.current_user_role_subject.next(1);

        this.router.navigate([``]);
        break;
      case 2: // Org Admin
        this.current_user_subject.next(login);
        this.is_authenticated_subject.next(true);
        this.current_user_role_subject.next(2);

        // this.router.navigate([``]);
        break;

      case 4: // Physio
        this.current_user_subject.next(login);
        this.is_authenticated_subject.next(true);
        this.current_user_role_subject.next(4);
        break;
      case 5: // Patient/User
        this.current_user_subject.next(login);
        this.is_authenticated_subject.next(true);
        this.current_user_role_subject.next(5);
        break;
      case 6: // moa
        this.current_user_subject.next(login);
        this.is_authenticated_subject.next(true);
        this.current_user_role_subject.next(6);
        break;
      default:
        break;
    }
    this.get_consent();
  }
  set_auth_subject2(login: Login) {

    this.current_user_subject.next(login);
    this.is_authenticated_subject.next(true);
    // let msg = 'text';
    switch (this.get_user_role()) {
      case 1: // Admin
        this.current_user_subject.next(login);
        this.is_authenticated_subject.next(true);
        this.current_user_role_subject.next(1);

        this.router.navigate([``]);
        break;
      case 2: // Org Admin
        this.current_user_subject.next(login);
        this.is_authenticated_subject.next(true);
        this.current_user_role_subject.next(2);

        // this.router.navigate([``]);
        break;

      case 4: // Physio
        this.current_user_subject.next(login);
        this.is_authenticated_subject.next(true);
        this.current_user_role_subject.next(4);
        break;
      case 5: // Patient/User
        this.current_user_subject.next(login);
        this.is_authenticated_subject.next(true);
        this.current_user_role_subject.next(5);
        break;
      case 6: // moa
        this.current_user_subject.next(login);
        this.is_authenticated_subject.next(true);
        this.current_user_role_subject.next(6);
        break;
      default:
        break;
    }
  }

  purge_all_auth() {
    this.destroy_tokens();
    // Set the current user to an empty object
    this.current_user_subject.next({} as Login);
    // Set auth status to false
    this.is_authenticated_subject.next(false);
    this.current_user_role_subject.next(0);
    //  (`Auth purged`);
    // this.router.navigate(['auth']);
  }


  // Registration Check
  check_is_multi_user(register_hash: String): boolean {
    // Returns True if multi account
    let is_multi_user: boolean;
    this.api_service.get(`${environment.endpoints.auth.is_multi_user}?register_hash=${register_hash}`)
      .toPromise().then(async (is_multi_account: any) => {
        if (is_multi_account.type === 'multi_account') {
          is_multi_user = true;
        } else {
          is_multi_user = false;
        }
      });

    return is_multi_user;
  }

  attempt_login(credentials: Object) {
    const url = window.location.origin;
    console.log(window.location.origin);
    return this.api_service.post(`${environment.endpoints.auth.login}`, credentials)
      .toPromise()
      .then((res) => {
        this.save_token(`access_token`, res.access_token);
        this.save_token(`refresh_token`, res.refresh_token);
        // this.get_consent();
        switch (this.get_user_role()) {
          case 1: // Admin


            break;
          case 2: // Org Admin
            this.api_service.get(`${environment.endpoints.org.orgs}${this.get_user_org_id()}/admins/${this.get_user_id()}`)
              .subscribe(
                org_login => {
                  this.set_auth_subject(org_login.login);
                  this.router.navigate([`/orgs`]);
                },
                error => {
                  this.purge_all_auth();
                }
              );
            break;
          case 4: // Physio
            this.api_service.get(`${environment.endpoints.physio.physios}${this.get_user_id()}`)
              .subscribe(
                physio_login => {
                  this.set_auth_subject(physio_login.login);
                  this.router.navigate([`/users`]);
                  // console.log(`Use from here?`, this.get_user_org_id());

                  if (this.get_user_org_id() === 9 && url === 'https://salaso.ie/') {
                    const novartis_popup = this.dialog.open(SalasoNovartisDialogComponent, {
                      disableClose: true
                    });
                  }

                },
                error => {
                  this.purge_all_auth();
                }
              );
            break;
          case 5: // Patient/User
            this.api_service.get(`${environment.endpoints.user.users}${this.get_user_id()}`)
              .subscribe(
                user_login => {
                  this.set_auth_subject(user_login.login);
                  this.router.navigate([`/programs`]);
                  if (this.get_user_org_id() === 9) {
                    const novartis_popup = this.dialog.open(SalasoNovartisDialogComponent, {
                      disableClose: true
                    });
                  }
                },
                error => {
                  this.purge_all_auth();

                }
              );
            break;
          default:
            // this.router.navigate([`auth`]);
            break;
        }

      }).catch(
      err => {
        throw err;
      }
      );


  }
  register_user(credentials: Object) {
    return this.api_service.post(`${environment.endpoints.auth.register}`, credentials)
      .subscribe(res => {
        if (res.success) {

          const dialogRef = this.dialog.open(SalasoRegisterSuccessDialogComponent, {
            height: '230px',
            width: '360px',
          });
          dialogRef.afterClosed().subscribe(result => {
            console.log('is this redirecting', result);
            this.router.navigate(['auth']);
          });

        }
      }, err => {
        let msg = "test";
        const dialogRef = this.dialog.open(SalasoRegisterErrorDialogComponent, {
          height: '230px',
          width: '360px',
            data: {
              message: msg
            }
        });
        dialogRef.afterClosed().subscribe(result => {
          // this.router.navigate(['auth']);
        });
      });
  }

  resend_registration_email(user_id: number, user_uuid: string) {
    const body = {
      'uuid': user_uuid
    };
    return this.api_service.post(`${environment.endpoints.user.users}${user_id}${environment.endpoints.auth.resend_invite}`, body);
  }

  save_token(tokenName: string, token: string): void {
    localStorage.setItem(tokenName, token);
  }

  get_token(tokenName: string): string {
    return localStorage.getItem(tokenName);
  }

  destroy_tokens(): Boolean {

    try {
      window.localStorage.removeItem('access_token');
      window.localStorage.removeItem('refresh_token');

      return true;
    } catch (error) {

      this.destroy_tokens();
      return false;

    }
  }

  refresh_token(): Observable<any> {
    const refresh = {
      refresh_token: this.get_token('refresh_token'),
      id: this.get_user_id()
    };

    return this.api_service.post(`${environment.endpoints.auth.refresh}`, refresh);

  }

  get_user_id(): number {
    try {
      if (jwt_decode(localStorage.getItem('access_token'))) {
        const refresh: any = jwt_decode(localStorage.getItem('access_token'));
        return refresh.payload.id;
      } else {
        throw new Error('No Token Found Please log in');
      }
    } catch (error) {

    }
  }

  get_user_org_id(): number {
    try {
      if (jwt_decode(localStorage.getItem('access_token'))) {
        const role_id: any = jwt_decode(localStorage.getItem('access_token'));
        return role_id.payload.Orgs[0].id;
      }
    } catch (error) {

    }
  }

  get_user_role(): number {
    try {
      if (jwt_decode(localStorage.getItem('access_token'))) {
        const token: any = jwt_decode(localStorage.getItem('access_token'));
        return token.payload.Login_Role.id;
      }
    } catch (error) {

    }
  }

  get_physio_id(): number {
    try {
      if (jwt_decode(localStorage.getItem('access_token'))) {
        const token: any = jwt_decode(localStorage.getItem('access_token'));
        return token.payload.Physio.id;
      }
    } catch (error) {

    }
  }

  get_patient_context_id(): number {
    try {
      if (jwt_decode(localStorage.getItem('access_token'))) {
        const token: any = jwt_decode(localStorage.getItem('access_token'));
        return token.payload.tw_pic;
      }
    } catch (error) {

    }
  }

  get_touchworks_speciality(): any {
    try {
      if (jwt_decode(localStorage.getItem('access_token'))) {
        const token: any = jwt_decode(localStorage.getItem('access_token'));
        return token.payload.tw_specialty;
      }
    } catch (error) {

    }
  }

  get_touchworks_default_template_org(): any {
    try {
      if (jwt_decode(localStorage.getItem('access_token'))) {
        const token: any = jwt_decode(localStorage.getItem('access_token'));
        return token.payload.tw_defaultTemplateOrg;
      }
    } catch (error) {

    }
  }
  is_dashboard_access(): any {
    try {
      if (jwt_decode(localStorage.getItem('access_token'))) {
        const token: any = jwt_decode(localStorage.getItem('access_token'));
        return token.payload.tw_dashboard_access;
      }
    } catch (error) {

    }
  }
  is_touchworks_user(): any {
    try {
      if (jwt_decode(localStorage.getItem('access_token'))) {
        const token: any = jwt_decode(localStorage.getItem('access_token'));
        return token.payload.tw;
      }
    } catch (error) {

    }
  }



  get_token_expiration_date(token: string): Date {
    const decoded: any = jwt_decode(token);
    if (decoded.exp === undefined) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  is_token_expired(token: string): boolean {
    if (!token) {
      return true;
    }
    const date = this.get_token_expiration_date(token);
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  update_user(login: Login, id: Number) {
    return this.api_service.put(`${environment.endpoints.user.users}${id}`, login)
      .subscribe(res => {
        this.current_user_subject.next(res.login as Login);

        const dialogRef = this.dialog.open(SalasoEditAccountDialogComponent, {
          width: '300px',
          height: '200px',
          // data: {
          //     message: msg
          // }
        });

      }, err => {

      });
  }

  update_user_as_physio(login: Login, id: Number) {
    console.log(`Here`);

    this.api_service.put(`${environment.endpoints.user.users}${id}`, login)
      .toPromise()
      .then(res => {
        // console.log(`Here is the one`, res);
        // console.log(login);
      });

    const dialogRef = this.dialog.open(SalasoEditAccountDialogComponent, {
      width: '300px',
      height: '200px',
      // data: {
      //     message: msg
      // }
    });

    // this.api_service.put(`${environment.endpoints.user.users}${id}`, login)
    //   .subscribe(res => {
    //     console.log(res);
    //     // if (res) {}
    //     const dialogRef = this.dialog.open(SalasoEditAccountDialogComponent, {
    //       width: '300px',
    //       height: '200px',
    //       // data: {
    //       //     message: msg
    //       // }
    //     });

    //   }, err => {

    //   });
  }


  update_physio(login: Login, id: Number) {
    return this.api_service.put(`${environment.endpoints.physio.physios}${id}`, login)
      .subscribe(res => {
        this.current_user_subject.next(res.login as Login);

        const dialogRef = this.dialog.open(SalasoEditAccountDialogComponent, {
          width: '300px',
          height: '200px',
          // data: {
          //     message: msg
          // }
        });
      }, err => {

      });
  }
  /**
   *
   * @public
   * @param {string} email
   * @memberof UserService
   */
  public password_reset(email: string) {
    const body = {
      email: email
    };
    return this.api_service.post(`${environment.endpoints.auth.password_reset}`, body);
  }

  public reset_password(newPassword: String, reset_hash: String) {
    const body = {
      newPassword: newPassword,
      reset_hash: reset_hash
    };
    return this.api_service.post(`${environment.endpoints.auth.reset_password}`, body);
  }

  public change_password(currentPassword: String, newPassword: String) {
    const body = {
      currentPassword: currentPassword,
      newPassword: newPassword
    };
    // tslint:disable-next-line:max-line-length
    return this.api_service.put(`${environment.endpoints.user.users}${this.get_user_id()}${environment.endpoints.auth.change_password}`, body);
  }

  public log_consent(credentials: Object) {
    return this.api_service.post(`/user${environment.endpoints.user.login_consents}`, credentials)
      .subscribe(res => {
        if (res.success) {
          // console.log('consent logged');
        }
      }, err => {
        // console.log('There was an error');
      });
  }

  public async get_consent() {

    const myDialog = this.dialog;

    await this.api_service.get(`/user${environment.endpoints.user.login_consents}`)
      .subscribe(res => {
        if (res.success) {
          res.consents.forEach(function (consent) {
            let activate_consent = false;
            consent.Consent_Checkboxes.forEach((consent_checkbox) => {
              if (consent_checkbox.Login_Consents.length === 0) {
                activate_consent = true;
              }
            });
            if (activate_consent) {
              const consentDialogRef = myDialog.open(SalasoConsentDialogComponent, {
                width: '600px',
                // height: '350px',
                data: {
                  consent: consent
                }
              });
            }
            console.log(activate_consent);
          });
        }
      }, err => {
        //  console.log('There was an error');
      });

  }
}


@Component({
  selector: 'salaso-register-success-dialog',
  template: `<h1 mat-dialog-title style="margin: 0 auto">Password Created!</h1><br>
<mat-dialog-content>
<br>
<h4 style="margin: 0 auto">You can now log in.</h4>
</mat-dialog-content>
<mat-dialog-actions>
</mat-dialog-actions>`,
})
export class SalasoRegisterSuccessDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SalasoRegisterSuccessDialogComponent>) {
    this.delay(5000).then(any => {
      this.closeDialog();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), 1000)).then(() => ('fired'));
  }
}

@Component({
  selector: 'salaso-register-error-dialog',
  template: `<h2 mat-dialog-title color="warn">Register</h2>
  
<mat-dialog-content>Password must be: 8 characters min, at least 1 lowercase, 1 uppercase, 1 number, 1 special character</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Okay</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <!-- <button mat-button [mat-dialog-close]="true">Yes</button> -->
</mat-dialog-actions>`,
})
export class SalasoRegisterErrorDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SalasoRegisterErrorDialogComponent>) {
    this.delay(3000).then(any => {
      // this.closeDialog();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), 1000)).then(() => ('fired'));
  }
}

@Component({
  selector: 'salaso-edit-dialog',
  template: `
  <h1 mat-dialog-title>Update Account</h1>
<mat-dialog-content>
    <!-- <p>{{data.message}}</p> -->
Account has been updated.<br><br>

<!-- Would you like to go back to the Exercises page?
    <form>
        <mat-checkbox color="primary">Create another User</mat-checkbox>
    </form> -->


</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="onYesClick()">Okay</button>
   <!-- <button mat-button (click)="onNoClick()">Cancel</button> -->
</mat-dialog-actions>`,
})
export class SalasoEditAccountDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SalasoEditAccountDialogComponent>, private router: Router, private user_service: UserService) { }

  onYesClick(): void {
    // TODO: Take org admin and Admin into account Roles (1, 2)
    if (this.user_service.get_user_role() === 4) {
      this.router.navigate(['users']);
    } else if (this.user_service.get_user_role() === 5) {
      this.router.navigate(['programs']);
    } else if (this.user_service.get_user_role() === 6) {
      this.router.navigate(['programs']);
    }
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'salaso-edit-dialog',
  template: `
  <h1 mat-dialog-title>Update Account</h1>
<mat-dialog-content>
    <!-- <p>{{data.message}}</p> -->
Your Account has not been updated.<br><br>
Some thing went wrong, please try again.

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="onYesClick()">Okay</button>
    <button mat-button (click)="onNoClick()">Cancel</button>
</mat-dialog-actions>`,
})
export class SalasoErrorEditAccountDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SalasoEditAccountDialogComponent>, private router: Router, private user_service: UserService) { }

  onYesClick(): void {
    // TODO: Take org admin and Admin into account Roles (1, 2)
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'salaso-consent-dialog',
  templateUrl: 'salaso-consent-dialog.html',
})
export class SalasoConsentDialogComponent {
  @ViewChildren('consentCheckbox') checkboxes: QueryList<MatCheckbox>;
  @ViewChild('confirmButton') confirm: MatButton;

  consent_form: FormGroup;
  checkbox_required: boolean;
  checkbox_amount = 0;
  required_count = 0;
  show_confirm = false;

  constructor(
    // tslint:disable-next-line:max-line-length
    public gdprDialogRef: MatDialogRef<SalasoConsentDialogComponent>, private api_service: ApiService, private router: Router, private user_service: UserService, private form_builder: FormBuilder, private route: ActivatedRoute, @Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialog, private http: Http) {

    this.consent_form = this.form_builder.group({
      'consent_checkbox': [false, [Validators.required]],
    });

    this.data.consent.Consent_Checkboxes.forEach(element => {
      if (element.required === 1) {
        this.checkbox_amount++;
      }
    });
    gdprDialogRef.disableClose = true;
  }

  checkbox_change($event, i, consent) {
    if (consent.Consent_Checkboxes[i].required === 1) {
      if ($event.checked) {
        this.required_count++;
      } else {
        this.required_count--;
      }
    }
    if (this.checkbox_amount === this.required_count) {
      this.show_confirm = true;
    } else {
      this.show_confirm = false;
    }
  }

  policyLink(consent): void {

    if (consent != null) {
    const httpOptions = {
      headers: new Headers({
        'Access-Control-Allow-Origin': 'http://localhost:3009/*',
        'x-access-token': `${this.user_service.get_token('access_token')}`,
        'responseType': 'blob'
      })
    };
    // console.log(`${environment.image_url}${consent}`);
    this.http.get(`${environment.image_url}${consent}`, httpOptions).subscribe((res: any) => {
      const dialogRef = this.dialog.open(SalasoPolicyDialogComponent, {
        width: '700px',
        // height: '700px',
        data: {
         consent: res._body
        }
      });
    }, err => { console.log(err);
    });

  } else {
    console.log('consent empty');
  }
  }

  onYesClick(consent): void {

    let index = 0;
    let agreed = 0;
    this.checkboxes.forEach(element => {
      const c = consent.Consent_Checkboxes[index];
      if (element.checked) {
        agreed = 1;
      } else {
        agreed = 0;
      }
      //    console.log('agreed ' + agreed);
      const consentItem = {
        'agreed': agreed,
        'consent_checkbox_id': c.id
      };
      this.user_service.log_consent(consentItem);
      index++;
    });
    this.gdprDialogRef.close();
  }
}

@Component({
  selector: 'salaso-policy-dialog',
  templateUrl: 'salaso-policy-dialog.html'
})
export class SalasoPolicyDialogComponent {
  constructor(
    // tslint:disable-next-line:max-line-length
    public policyDialogRef: MatDialogRef<SalasoPolicyDialogComponent>, private api_service: ApiService, private router: Router, private user_service: UserService, private form_builder: FormBuilder, private route: ActivatedRoute, @Inject(MAT_DIALOG_DATA) public data) {

  }

  onYesClick(): void {
    this.policyDialogRef.close();
  }

  // onNoClick(): void {
  //   this.dialogRef.close();
  // }
}

@Component({
  selector: 'salaso-novartis-dialog',
  template: `
  <h1 mat-dialog-title style="margin-bottom: 0">
  <img class="novartis-logo" src="assets/images/novartis_logo.jpg" style="max-width: 200px; height: auto; display: block; margin: 0 auto;">
  </h1>
<mat-dialog-content>
<div style="margin: 0 auto; width: 80%;">
<p style="text-align:justify">The Salaso Platform is sponsored by Novartis Ireland Ltd. <br>
The information within this platform is provided to you by Salaso<br> and your St. James’s Hospital Rheumatology Team.<br>
Novartis Ireland Ltd. have no input into any of the content included on this platform.</p>
<br>
<p>IE02/COS18-CNF050</p>
<p>Date of Preparation: September 2018</p>
</div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button color="primary" tabindex="-1" (click)="onYesClick()" style="display: block; margin: 2% auto;">Proceed</button>
</mat-dialog-actions>`
})
export class SalasoNovartisDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SalasoNovartisDialogComponent>,
    private router: Router,
    private user_service: UserService
  ) {
    dialogRef.disableClose = true;
  }

  onYesClick(): void {
    this.dialogRef.close();
  }

  // onNoClick(): void {
  //   this.dialogRef.close();
  // }
}
