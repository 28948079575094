import { Component, OnInit, OnDestroy } from '@angular/core';
import { SurveyService } from '../../survey.service';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CompleteSurveyDialog } from './complete-survey-dialog.component';
import { CompleteSurvey } from '@app/shared/models';

@Component({
    selector: 'salaso-complete-survey',
    templateUrl: './complete-survey.component.html',
    styleUrls: ['./complete-survey.component.scss']
})
export class CompleteSurveyComponent implements OnInit, OnDestroy {
    inviteId: string;

    surveyBody: CompleteSurvey;

    subs = new SubSink();

    constructor(
        private surveyService: SurveyService,
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.getParamsFromUrl();
    }

    getParamsFromUrl() {
        this.inviteId = this.route.snapshot.paramMap.get('inviteId');
        const { c, s, ss, to } = this.route.snapshot.queryParams;

        this.surveyBody = {
            respondentId: this.route.snapshot.paramMap.get('respondentId'),
            score: s,
            subScore: ss,
            category: c,
            triageResponse: to
        };

        this.completeSurvey();
    }

    completeSurvey() {
        this.subs.sink = this.surveyService.completeSurvey(this.inviteId, this.surveyBody).subscribe(
            (response) => this.onCompleteSurveySuccess(response),
            (error) => this.onCompleteSurveyError(error)
        );
    }

    onCompleteSurveySuccess(response) {
        if (response.surveyInvite.Survey.isPhysioSurvey) {
            this.router.navigate([`users/user/${response.surveyInvite.login_id}`]);
        } else {
            this.router.navigate(['programs/surveys']);
        }

        this.openOkDialog();
    }

    openOkDialog() {
        this.dialog.open(CompleteSurveyDialog, {
            width: '35rem',
            panelClass: 'rounded-border-dialog',
            ariaDescribedBy: 'dialog-title',
            role: 'alertdialog'
        });
    }

    onCompleteSurveyError(error) {
        // this.eventsService.logErrorEvent(error, window.location.href);
        // this.eventsService.displayErrorSnackbar('Error completing questionnaire');
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
