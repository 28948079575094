import { Component, OnInit } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { Login } from '@app/shared/models/';
import { Router } from '@angular/router';

@Component({
  selector: 'salaso-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {


  user: Login;
  is_authenticated: Boolean;

  constructor(private user_service: UserService, private router: Router) {}

  ngOnInit() {
      this.user_service.current_user.subscribe((res: Login) => { this.user = res; });
      this.user_service.is_authenticated.subscribe(res => { this.is_authenticated = res; });
  }

  logout () {
    this.user_service.purge_all_auth();
    this.router.navigate(['auth']);
  }


}
