import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material';

@Component({
  selector: 'salaso-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Output() date: EventEmitter<String> = new EventEmitter<String>();
  selected_date: Date = new Date();
  today_date: Date = new Date();

  constructor(private adapter: DateAdapter<any>) {
    this.adapter.setLocale('en-us');
    // this.selected_date;
  }

  ngOnInit() {
  }

  // Gets selected date and emits it to stats component
  pick_date(event: Date) {
    this.selected_date = event;
    console.log(event);
    this.date.emit(event.toISOString());
  }

}
