import { Component, OnInit, Input } from '@angular/core';
import { LoggingService } from '@app/shared/services';
import { UserActivityScore } from '@app/shared/models/user_activity_score.model';
import * as moment from 'moment';

@Component({
  selector: 'salaso-activity-chart',
  templateUrl: './activity-chart.component.html',
  styleUrls: ['./activity-chart.component.scss']
})
export class ActivityChartComponent implements OnInit {
  // holding data from services
  user_activity: UserActivityScore;
  @Input() user_id: Number;

  // dates
  start_date: Date = new Date();
  end_date: Date = new Date();

  // chart data
  chartOptions: any;
  chartLabels: any = [];
  chartType: String;
  chartData: any = [];

  // Checking if chart is ready
  isChartReady = false;

  // Gets date from datepicker and refresh user_activity data and chart
  @Input()
  set update_date(date: Date) {
    this.start_date = new Date(date);
    this.end_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() + 6);
    this.chartData.length = 0;
    this.get_logging_service();
  }

  constructor(private logging_service: LoggingService) {
    // Getting the start date, start date is today's date minus 7 days
    // this.start_date.setFullYear(this.end_date.getFullYear(), this.end_date.getMonth(), this.end_date.getDate() - 6);
  }

  ngOnInit() {

  }

  // Getting user activity data from date range
  get_logging_service() {
    /**
     * @param {Number} user_id
     * @param {String} start_date
     * @param {String} end_date
     * @memberof ActivityChartComponent
     */
    this.logging_service.get_user_activity_scores(this.user_id, this.start_date.toISOString(), this.end_date.toISOString())
      .subscribe((res: UserActivityScore) => {
        this.user_activity = res;
        this.initChart();
      }, err => {
        this.initChart();
      });
  }

  // Initializing chart
  public initChart() {
    // Chart Type
    this.chartType = 'line';

    // Chart Labels
    this.chartLabels = this.getDates(this.user_activity.date_range.from, this.user_activity.date_range.to);

    // Chart Data
    // Gets each activity object and pushes score and date to data
    this.chartData.push({
      data: [],
      fill: false,
      pointRadius: 10,
      pointHoverRadius: 15,
      showLine: false,
      responsive: true
    });
    if (!this.user_activity.activities[0]) {
      return;
    }
    this.user_activity.activities[0].Activity_Scores.forEach((activity, i) => {
      this.chartData[0].data.push({
        x: moment(activity.date).format('DD/MM'),
        y: activity.score
      });
    });

    // Chart Options
    let text;
    if (this.user_activity.activities[0].title === null) {
      text = 'Patient Goal not yet set';
    } else {
      text = `Patient Goal: ${this.user_activity.activities[0].title}`;
    }
    this.chartOptions = {
      responsive: true,
      // Title of graph will be the Patients goal
      title: {
        display: true,
        text: text,
        position: 'bottom'
      },
      // Tooltip when hovering over point
      tooltips: {
        callbacks: {
          title: function (t, d) {
            return d.datasets[t[0].datasetIndex].data[t[0].index].x;
          },
          label: function (t, d) {
            return 'Date: ' + d.datasets[t.datasetIndex].data[t.index].x +
              ', Score: ' + t.yLabel;
          }
        }
      },
      // X axis and Y axis scales, put Y axes to 0-10
      scales: {
        yAxes: [{
          ticks: {
            suggestedMin: 0,
            suggestedMax: 10,
            fontColor: '#ccc'
            // stepSize: 2
          }
        }],
        xAxes: [{
          ticks: {
            fontColor: '#aaa'
          }
        }]
      },
      // Legend positioned at bottom, toggles on or off
      legend: {
        display: false,
        position: 'bottom'
      },
      // Point shape is circle
      elements: {
        point: {
          pointStyle: 'circle'
        }
      },
      // Individual hovering of each point
      hover: {
        mode: 'x'
      }
    };

    // Chart is now ready to be displayed
    this.isChartReady = true;
  }

  // Event when chart is clicked
  public chartClicked(e: any): void {

  }

  // Event when chart is hovered
  public chartHovered(e: any): void {

  }

  // Gets array of two selected dates
  // Used to get the values of X axis scale
  getDates(startDate, stopDate) {
    const dateArray = [];
    let currentDate = moment(startDate);
    dateArray.push(moment(currentDate).format('DD/MM'));
    stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      currentDate = moment(currentDate).add(1, 'days');
      dateArray.push(moment(currentDate).format('DD/MM'));
    }
    return dateArray;
  }

  // Refreshes user_activity data and chart with previous day
  prevDay() {
    this.start_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() - 1);
    this.end_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() + 6);
    this.chartData.length = 0;
    this.chartLabels.length = 0;
    this.get_logging_service();
  }

  // Refreshes user_activity data and chart with next day
  nextDay() {
    this.start_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() + 1);
    this.end_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() + 6);

    this.chartData.length = 0;
    this.get_logging_service();
  }
}
