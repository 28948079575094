import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { LoggingService } from '@shared/services/logging.service';

@Component({
  selector: 'salaso-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  authForm: FormGroup;

  constructor(private form_builder: FormBuilder, private logging_service: LoggingService) {

    this.authForm = this.form_builder.group({
      'activity': ['', Validators.nullValidator]
    });
  }

  ngOnInit() {


  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }
      return Math.round(value) + '/10';
  }


  updateActivity() {
    this.logging_service.create_activity_score(1, this.authForm.get('activity').value);
  }
}
