import { Component, OnInit, Input } from '@angular/core';
import { LoggingService } from '@app/shared/services';
import { UserPain } from '@app/shared/models/user_pain.model';
import * as moment from 'moment';

@Component({
  selector: 'salaso-pain-chart',
  templateUrl: './pain-chart.component.html',
  styleUrls: ['./pain-chart.component.scss']
})
export class PainChartComponent implements OnInit {
  // holding data from services
  user_pain: UserPain;
  @Input() user_id: Number;

  // dates
  start_date: Date = new Date();
  end_date: Date = new Date();

  // chart data
  chartOptions: any;
  chartLabels: any = [];
  chartType: String;
  chartData: any = [];

  // Checking if chart is ready
  isChartReady = false;

  // Gets date from datepicker and refresh user_pain data and chart
  @Input()
  set update_date(date: Date) {
    this.start_date = new Date(date);
    this.end_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() + 6);
    console.log(this.start_date, this.end_date);
    this.chartData.length = 0;
    this.get_logging_service();
  }

  constructor(private logging_service: LoggingService) {
    // Getting the start date, start date is today's date minus 7 days
    // this.start_date.setFullYear(this.end_date.getFullYear(), this.end_date.getMonth(), this.end_date.getDate() - 6);
  }

  ngOnInit() {

  }

  // Getting user pain data from date range
  get_logging_service() {
    /**
     * @param {Number} user_id
     * @param {String} start_date
     * @param {String} end_date
     * @memberof PainChartComponent
     */
    this.logging_service.get_user_pains(this.user_id, this.start_date.toISOString(), this.end_date.toISOString())
      .subscribe((res: UserPain) => {
        this.user_pain = res;
        this.initChart();
      });
  }

  // Initializing chart
  public initChart() {
    // Chart Type
    this.chartType = 'line';

    // Chart Labels
    this.chartLabels = this.getDates(this.user_pain.date_range.from, this.user_pain.date_range.to);

    // Chart Data
    // Gets each pain object and pushes pain_val and date to data
    this.chartData.push({
      data: [],
      fill: false,
      pointRadius: 10,
      pointHoverRadius: 15,
      pointBackgroundColor: 'rgb(255, 0, 0)',
      showLine: false,
      responsive: true
    });
    // Only gets back the latest pain for that day
    const new_pain = [];
    this.user_pain.pains.reverse().forEach((pain) => {
      const pain_date = moment(pain.createdAt).format('DD/MM');
      if (new_pain.indexOf(pain_date) === -1) {
        this.chartData[0].data.push({
          x: pain_date,
          y: pain.pain_val
        });
        new_pain.push(pain_date);
      }
    });


    // Chart Options
    this.chartOptions = {
      responsive: true,
      // Tooltip when hovering over point
      tooltips: {
        callbacks: {
          title: function (t, d) {
            return d.datasets[t[0].datasetIndex].data[t[0].index].x;
          },
          label: function (t, d) {
            return 'Date: ' + d.datasets[t.datasetIndex].data[t.index].x +
              ', Pain: ' + t.yLabel;
          }
        }
      },
      // X axis and Y axis scales, put Y axes to 0-10
      scales: {
        yAxes: [{
          ticks: {
            suggestedMin: 0,
            suggestedMax: 10,
            fontColor: '#aaa'
            // stepSize: 2
          }
        }],
        xAxes: [{
          ticks: {
            fontColor: '#aaa'
          }
        }]
      },
      // Turning legend off
      legend: {
        display: false,
        position: 'bottom'
      },
      // Point shape is circle
      elements: {
        point: {
          pointStyle: 'circle'
        }
      },
      // Individual hovering of each point
      hover: {
        mode: 'x'
      }
    };

    // Chart is now ready to be displayed
    this.isChartReady = true;
  }

  // Event when chart is clicked
  public chartClicked(e: any): void {
    //  (e);
  }

  // Event when chart is hovered
  public chartHovered(e: any): void {
    //  (e);
  }

  // Gets array of two selected dates
  // Used to get the values of X axis scale
  getDates(startDate, stopDate) {
    const dateArray = [];
    let currentDate = moment(startDate);
    dateArray.push(moment(currentDate).format('DD/MM'));
    stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      currentDate = moment(currentDate).add(1, 'days');
      dateArray.push(moment(currentDate).format('DD/MM'));
    }
    return dateArray;
  }

  // Refreshes user_pain data and chart with previous day
  prevDay() {
    this.start_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() - 1);
    this.end_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() + 6);
    this.chartData.length = 0;
    this.get_logging_service();
  }

  // Refreshes user_pain data and chart with next day
  nextDay() {
    this.start_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() + 1);
    this.end_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() + 6);
    this.chartData.length = 0;
    this.get_logging_service();
  }

}
