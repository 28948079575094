import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'complete-survey-dialog',
    templateUrl: 'complete-survey-dialog.component.html',
    styleUrls: ['./complete-survey.component.scss']
})
export class CompleteSurveyDialog {

    constructor(
        public dialogRef: MatDialogRef<CompleteSurveyDialog>,
        @Inject(MAT_DIALOG_DATA) public data) { }

    handleCloseDialog() {
        this.dialogRef.close();
    }
}