import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiService } from '@app/shared/services/api.service';
import { SurveyForm, CompleteSurvey, RecurringSurveyForm } from '@app/shared/models/survey.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SurveyService {

    constructor(private apiService: ApiService) { }

    getSurvey(surveyId: string) {
        return this.apiService.get(`${environment.endpoints.survey.surveys}${surveyId}`);
    }

    getSurveys(limit = 20, offset = 0, searchWord = '', isPhysioSurvey = '', orgId = '') {
        return this.apiService.get(`${environment.endpoints.survey.surveys}?limit=${limit}&offset=${offset}&search=${searchWord}&isPhysioSurvey=${isPhysioSurvey}&orgId=${orgId}`);
    }

    getOrgSurveys(orgId: string, limit = 20, offset = 0, searchWord = '') {
        return this.apiService.get(`${environment.endpoints.org.orgs}${orgId}${environment.endpoints.survey.surveys}?limit=${limit}&offset=${offset}&search=${searchWord}`);
    }

    getPhysioOnlyOrgSurveys(orgId: number, limit = 20, offset = 0, searchWord = '') {
        return this.apiService.get(`${environment.endpoints.org.orgs}${orgId}${environment.endpoints.survey.surveys}?limit=${limit}&offset=${offset}&search=${searchWord}&isPhysioSurvey=1`);
    }

    getPatientOnlyOrgSurveys(orgId: number, limit = 20, offset = 0, searchWord = '') {
        return this.apiService.get(`${environment.endpoints.org.orgs}${orgId}${environment.endpoints.survey.surveys}?limit=${limit}&offset=${offset}&search=${searchWord}&isPhysioSurvey=0`);
    }

    getPatientOrgSurveys(orgId: string, limit = 20, offset = 0, searchWord = '') {
        return this.apiService.get(`${environment.endpoints.org.orgs}${orgId}${environment.endpoints.survey.patientSurveys}?limit=${limit}&offset=${offset}&search=${searchWord}`);
    }

    getSurveyInvites(userLoginId: string, limit = 10, offset = 0, isComplete = 0, isStatus = 1, isPhysioSurvey = 0, surveyId = '') {
        const params = new HttpParams()
            .set('userLoginId', userLoginId)
            .set('limit', limit.toString())
            .set('offset', offset.toString())
            .set('complete', isComplete.toString())
            .set('status', isStatus.toString())
            .set('isPhysioSurvey', isPhysioSurvey.toString())
            .set('surveyId', surveyId)
            .set('includeAnswers', '1');

        return this.apiService.get(`${environment.endpoints.survey.surveyInvites}`, params);
    }

    getSurveyGraph(surveyId: string, userId: string, fromDate?, toDate?) {
        return this.apiService.get(`${environment.endpoints.survey.surveyGraph}${surveyId}${environment.endpoints.user.users}${userId}?from=${fromDate}&to=${toDate}`);
    }

    getSurveysAssignedList(userId: string) {
        return this.apiService.get(`${environment.endpoints.survey.surveysAssigned}${environment.endpoints.user.users}${userId}`);
    }

    getSurveyAnswers(surveyId: string) {
        return this.apiService.get(`${environment.endpoints.survey.surveyInvites}${surveyId}${environment.endpoints.survey.answers}`);
    }

    downloadSurveyAnswers(surveyId: string, surveyNameObject: {name: string}) {
        this.apiService.get_pdf(`${environment.endpoints.survey.surveyInvites}${surveyId}${environment.endpoints.survey.answers}${environment.endpoints.survey.pdf}`, surveyNameObject);
    }

    createSurvey(surveyBody: SurveyForm) {
        return this.apiService.post(`${environment.endpoints.survey.surveys}`, surveyBody);
    }

    assignSurveyToOrg(surveyId: string, orgId: string) {
        return this.apiService.post(`${environment.endpoints.survey.surveys}${surveyId}${environment.endpoints.survey.assignToOrg}`, { org_id: orgId });
    }

    assignPatientSurveyToOrg(surveyId: string, orgId: string) {
        return this.apiService.post(`${environment.endpoints.survey.patientSurveys}${surveyId}${environment.endpoints.survey.assignToOrg}`, { org_id: orgId });
    }

    createSurveyInvite(surveyId: string, userLoginId: string, patientGroupId?: string, groupingUuid?: string) {
        const surveyBody = { surveyId, userLoginId, patientGroupId, groupingUuid };
        return this.apiService.post(`${environment.endpoints.survey.surveyInvites}`, surveyBody);
    }

    createRecurringSurveyInvite(recurringSurvey: RecurringSurveyForm) {
        return this.apiService.post(`${environment.endpoints.survey.surveyInvites}`, recurringSurvey);
    }

    createPatientOrgSurveyInvite(surveyId: string, userLoginId: string, orgId: string) {
        return this.apiService.post(`${environment.endpoints.survey.patientOrgSurveys}${environment.endpoints.survey.invite}`, { surveyId, userLoginId, orgId });
    }

    completeSurvey(inviteId: string, surveyBody: CompleteSurvey) {
        return this.apiService.post(`${environment.endpoints.survey.surveyInvites}${inviteId}${environment.endpoints.survey.complete}`, surveyBody);
    }

    updateSurvey(surveyId: string, surveyBody: SurveyForm) {
        return this.apiService.put(`${environment.endpoints.survey.surveys}${surveyId}`, surveyBody);
    }

    removeSurvey(surveyId: string) {
        return this.apiService.put(`${environment.endpoints.survey.surveys}${surveyId}`, { status: 0 });
    }

    removeSurveyFromOrg(surveyId: string, orgId: string) {
        return this.apiService.delete(`${environment.endpoints.survey.surveys}${surveyId}${environment.endpoints.survey.removeFromOrg}`, { org_id: orgId });
    }

    removePatientSurveyFromOrg(surveyId: string, orgId: string) {
        return this.apiService.delete(`${environment.endpoints.survey.patientSurveys}${surveyId}${environment.endpoints.survey.removeFromOrg}`, { org_id: orgId });
    }

    revokeSurveyInvite(inviteId: string) {
        return this.apiService.delete(`${environment.endpoints.survey.surveyInvites}${inviteId}`, {});
    }

    removePatientOrgSurveyInvite(inviteId: string, userLoginId: string) {
        return this.apiService.delete(`${environment.endpoints.survey.surveyInvites}${inviteId}${environment.endpoints.survey.patientOrgSurveys}`, { userLoginId });
    }
}
