import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as pdfjsLib from 'pdfjs-dist';

@Component({
    selector: 'salaso-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
    @ViewChild('pdfViewerContainer') pdfViewerContainer: ElementRef;

    @Input() src;

    document;
    pdf;
    pdfWorkerSrc: string;

    scale = 1;
    scaleIncrement = 0.1;
    zoomLevel = 100;
    minZoom = 50;
    maxZoom = 150;
    zoomIncrement = 10;
    currentPageNumber = 1;
    oldScrollPos = 0;

    canvases = [];

    constructor(@Inject(DOCUMENT) document) {
        this.document = document;
    }

    ngOnInit() {
        this.createPdfWorkerSrc();
        this.setupPdfViewer();
    }

    createPdfWorkerSrc() {
        if (window.hasOwnProperty('pdfWorkerSrc') && typeof (window as any).pdfWorkerSrc === 'string' && (window as any).pdfWorkerSrc) {
            // TODO: Get this if statement to work, atm keeps creating the worker.
            pdfjsLib.GlobalWorkerOptions.workerSrc = (window as any).pdfWorkerSrc;
        } else {
            this.pdfWorkerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
            pdfjsLib.GlobalWorkerOptions.workerSrc = this.pdfWorkerSrc;
        }
    }

    setupPdfViewer() {
        const srcAsPdf = URL.createObjectURL(this.src);

        pdfjsLib.getDocument(srcAsPdf).promise.then(
            (pdf) => this.onGetDocumentSuccess(pdf),
            (error) => this.onError(error)
        );
    }

    onGetDocumentSuccess(pdf) {
        this.pdf = pdf;

        for (let page = 1; page <= pdf.numPages; page++) {
            const canvas = this.document.createElement('canvas');
            canvas.className = 'pdf-page-canvas';
            this.pdfViewerContainer.nativeElement.appendChild(canvas);
            this.renderPage(page, canvas);
        }
    }

    renderPage(pageNumber, canvas) {
        this.pdf.getPage(pageNumber).then(
            (page) => this.onGetPageSuccess(page, canvas),
            (error) => this.onError(error)
        );
    }

    onGetPageSuccess(page, canvas) {
        const viewport = page.getViewport({ scale: this.scale });

        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        this.canvases.push(canvas);

        const renderContext = {
            canvasContext: context,
            viewport: viewport
        };

        const renderTask = page.render(renderContext);
    }

    reRenderPage() {
        this.canvases.forEach((canvas, index) => {
            this.renderPage(index + 1, canvas)
        });
    }

    handleZoomOut() {
        if (this.zoomLevel > this.minZoom) {
            this.zoomLevel -= this.zoomIncrement;
            this.scale -= this.scaleIncrement;
            this.reRenderPage();
        }
    }

    handleZoomIn() {
        if (this.zoomLevel < this.maxZoom) {
            this.zoomLevel += this.zoomIncrement;
            this.scale += this.scaleIncrement;
            this.reRenderPage();
        }
    }

    onPageScroll(event) {
        const scrollTop = event.target.scrollTop;

        if (this.oldScrollPos < scrollTop && scrollTop >= (this.canvases[0].height * this.currentPageNumber) - this.canvases[0].height / 3) {
            this.currentPageNumber += 1;
        } else if (this.currentPageNumber > 1 && this.oldScrollPos > scrollTop && scrollTop <= this.canvases[0].height * (this.currentPageNumber - 1) - this.canvases[0].height / 2) {
            this.currentPageNumber -= 1;
        }

        this.oldScrollPos = scrollTop;
    }

    onError(error) {
        // console.error(error);
        // TODO: eventService error handling
    }

    ngOnDestroy() {
        this.pdf.destroy();
        this.src = undefined;
        pdfjsLib.GlobalWorkerOptions.workerSrc = undefined;
    }
}
