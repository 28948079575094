import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiService } from '@app/shared/services/api.service';


@Injectable()
export class LeafletService {
    constructor(private api_service: ApiService) { }

    public create_leaflets(leaflet: any) {
        return this.api_service.post(`${environment.endpoints.leaflet.leaflets}`, leaflet);
    }

    public create_user_leaflets(user_id: number, leaflet_id: string) {
        const body = {
            leaflet_id: leaflet_id
        };
        return this.api_service.post(`${environment.endpoints.user.users}${user_id}${environment.endpoints.leaflet.user_leaflets}`, body);
    }

    public get_user_leaflets(user_id: number) {
        return this.api_service.get(`${environment.endpoints.user.users}${user_id}${environment.endpoints.leaflet.user_leaflets}`);
    }

    public get_physio_leaflets(physio_id: number, limit?: number, offset?: number, search?: string) {
        if (search) {
            // tslint:disable-next-line:max-line-length
            return this.api_service.get(`${environment.endpoints.physio.physios}${physio_id}${environment.endpoints.leaflet.leaflets}?limit=${limit}&offset=${offset}&search=${search}`);
        }

        if (limit || offset) {
            // tslint:disable-next-line:max-line-length
            return this.api_service.get(`${environment.endpoints.physio.physios}${physio_id}${environment.endpoints.leaflet.leaflets}?limit=${limit}&offset=${offset}`);
        } else {
            return this.api_service.get(`${environment.endpoints.physio.physios}${physio_id}${environment.endpoints.leaflet.leaflets}`);
        }
    }

    public get_org_leaflets(org_id: number, limit?: number, offset?: number, search?: string) {
        if (search) {
            // tslint:disable-next-line:max-line-length
            return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.leaflet.leaflets}?limit=${limit}&offset=${offset}&search=${search}`);
        }

        if (limit || offset) {
            // tslint:disable-next-line:max-line-length
            return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.leaflet.leaflets}?limit=${limit}&offset=${offset}`);
        } else {
            return this.api_service.get(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.leaflet.leaflets}`);
        }
    }

    public delete_physio_leaflet(physio_id: number, leaflet_id: string) {
        // tslint:disable-next-line:max-line-length
        return this.api_service.delete(`${environment.endpoints.physio.physios}${physio_id}${environment.endpoints.leaflet.leaflets}${leaflet_id}`, {});
    }

    public delete_org_leaflet(org_id: number, leaflet_id: string) {
        // tslint:disable-next-line:max-line-length
        return this.api_service.delete(`${environment.endpoints.org.orgs}${org_id}${environment.endpoints.leaflet.leaflets}${leaflet_id}`, {});
    }

}
