import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsComponent } from './terms/terms.component';
import { StaticRoutingModule} from './static.routing';
import { RouterGaurdService } from '@app/shared/gaurds/router-gaurd.service';
import {SharedModule} from '@app/shared/shared.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
  imports: [CommonModule, StaticRoutingModule, SharedModule],
  providers: [RouterGaurdService],
  declarations: [TermsComponent, PrivacyPolicyComponent]
})
export class StaticModule {}
