import { Component, OnInit } from '@angular/core';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'salaso-icon-nav',
  templateUrl: './icon-nav.component.html',
  styleUrls: ['./icon-nav.component.scss']
})
export class IconNavComponent implements OnInit {
  tab: String;
  current_user_role: string;
  mobileView = false;
  // user-md = this.user-md;
  constructor(private user_service: UserService) {}

  ngOnInit() {
    this.user_service.current_user_role.subscribe(res => {
      switch (res) {
        case 1:
          this.current_user_role = 'admin';
          break;
        case 2:
          this.current_user_role = 'org_admin';
          break;
        case 4:
          this.current_user_role = 'practitioner';
          this.tab = 'patients';
          break;
        case 5:
          this.current_user_role = 'user';
          this.tab = 'exercises';
          break;

        default:
          break;
      }
    });
    if (window.innerWidth <= 768) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  activate_background(tab: String) {
    this.tab = tab;
  }

  onResize($event) {
    if (window.innerWidth <= 768) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }
}
